/*=[ MOBILE NAV ICONS ]=======================================================*/

$line-width: 15px;
$line-height: 2px;
$line-spacing: 6px;
$line-x-offset: 30px;
$line-y-offset: -3px;

@mixin line {
  display: inline-block;
  width: $line-width;
  height: $line-height;
}

.lines {
  //create middle line
  @include line;
  position: relative;

  //create the upper and lower lines as pseudo-elements of the middle line
  &:before, &:after {

    @include line;
    position: absolute;
    left:0;
    content: '';
  }
  &:before { top: $line-spacing; }
  &:after { top: -$line-spacing; }
}

.lines-button {
  position: relative;
  z-index: 32;
  transition: transform .3s ease;
  &.close {
    transform: translateX($line-x-offset);
  }
  padding: 0 10px 10px 0;
}

.lines-button .lines{
  //transition: background .3s .6s ease, transform .3s .3s, ease;
  transform: translateY($line-y-offset);

  &:before, &:after{
    //set transform origin back to center
    transform-origin: 50% 50%;
    transition: top .3s .6s ease, transform .3s ease;
  }
}

.lines-button.close .lines{
  transition: background .3s 0s ease, transform .3s .3s, ease;
  background: transparent !important;

  &:before, &:after{
    transition: top .3s ease, transform .3s .5s ease;
    top:0;
    width: $line-width;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}

/*=[ HEADER ]=================================================================*/

@mixin logo-black() {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  @include breakpoint(small) {
    background-image: url('sd-logo-black-small.png');
    width: 28px;
    height: 30px;
  }
  @include breakpoint(medium) {
    background-image: url('sd-logo-black-medium.png');
    width: 33px;
    height: 36px;
  }
  @include breakpoint(large) {
    background-image: url('sd-logo-black-large.png');
    width: 66px;
    height: 45px;
  }
  @include breakpoint(xlarge up) {
    background-image: url('sd-logo-black-xlarge.png');
    width: 90px;
    height: 61px;
  }
}

@mixin logo-white(){
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  @include breakpoint(small) {
    background-image: url('sd-logo-white-small.png');
    width: 28px;
    height: 30px;
  }
  @include breakpoint(medium) {
    background-image: url('sd-logo-white-medium.png');
    width: 33px;
    height: 36px;
  }
  @include breakpoint(large) {
    background-image: url('sd-logo-white-large.png');
    width: 66px;
    height: 45px;
  }
  @include breakpoint(xlarge up) {
    background-image: url('sd-logo-white-xlarge.png');
    width: 90px;
    height: 61px;
  }
}

@mixin search-black() {
  @include resp-sprite-icon-hidpi(
    $icon-search-black-small,
    $icon-search-black-medium,
    $icon-search-black-large,
    $icon-search-black-xlarge);
}

@mixin search-white() {
  @include resp-sprite-icon-hidpi(
    $icon-search-white-small,
    $icon-search-white-medium,
    $icon-search-white-large,
    $icon-search-white-xlarge);
}

// Label, S offset, M offset, L offset, XL offset,
// S width, M width, L width, XL width, color
$Projects: (Projects, 0, 0, 0, 0, 0, 0, 49px, 64px, #000);
$Ideas: (Ideas, 55px, 73px, 80px, 96px, 0, 0px, 34px, 44px, #000);
$About: (About, 100px, 130px, 140px, 175px, 0, 0, 40px, 55px, #FFF);
$People: (People, 155px, 200px, 210px, 263px, 0, 0, 40px, 54px, #FFF);
$Contact: (Contact, 215px, 275px, 280px, 352px, 0, 0, 50px, 66px, #FFF);
$nav-items: ($Projects, $Ideas, $About, $People, $Contact);

@keyframes navHighlightFromHome{
  from {
    top: 2px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes navHighlightHome{
  to {
    top: 2px;
    opacity: 0;
  }
}


@each $item in $nav-items {
  @include breakpoint(small only) {
    @keyframes navHighlight#{nth($item, 1)}{
      to {
        left: nth($item, 2);
        width: nth($item, 6);
        //border-bottom-color: nth($item, 10);
      }
    }
  }
  @include breakpoint(medium only) {
    @keyframes navHighlight#{nth($item, 1)}{
      to {
        left: nth($item, 3);
        width: nth($item, 7);
        //border-bottom-color: nth($item, 10);
      }
    }
  }
  @include breakpoint(large only) {
    @keyframes navHighlight#{nth($item, 1)}{
      to {
        left: nth($item, 4);
        width: nth($item, 8);
        //border-bottom-color: nth($item, 10);
      }
    }
  }
  @include breakpoint(xlarge only) {
    @keyframes navHighlight#{nth($item, 1)}{
      to {
        left: nth($item, 5);
        width: nth($item, 9);
        //border-bottom-color: nth($item, 10);
      }
    }
  }
}

@keyframes animateProgressBar{
  from {
    width: 0%;
  }
  to {
    width: 100%
  }
}

#header {
  z-index: 30;
  @include resp-calc(height, 70, 70, 70, 125);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.10);

  &.admin-bar {
    @include resp-calc(top, 46, 46, 28, 28);
  }
  &.white {
    border-bottom: solid 1px rgba(255, 255, 255, 0.35);
  }
  .row{
    position: relative;
  }

  #nav {
    position: absolute;
    right: 0;
    @include breakpoint(medium down) {
      right:15px;
    }
    @include resp-calc(margin-top, 26, 23, 20, 52);

    .nav-menu {
      position: relative;
      .underline{
        position: absolute;
        @include resp-calc(top, 0, 0, 48, 71);
        height: 1px;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        animation-duration: 200ms;
        //transition: left 200ms ease, width 200ms ease, border-bottom-color 200ms ease;
        @each $item in $nav-items {
          &.#{nth($item, 1)}{
            @include resp-val(left,
            nth($item, 2), nth($item, 3),
            nth($item, 4), nth($item, 5));
            @include resp-val(width,
            nth($item, 6), nth($item, 7),
            nth($item, 8), nth($item, 9));
            //border-bottom-color: nth($item, 10);
          }
        }
      }
      @include breakpoint(medium down) {
        .nav-menu-pages-wrapper{
          clip-path: inset(0 30px 0 0);
          position: absolute;
          z-index: 31;
          top: 0;
          @include resp-calc(left, -30, -100, 0, 0);
          width: 355px;
          height: 30px;
          overflow: hidden;
          pointer-events: none;
          & > *{
            pointer-events: auto;
          }
        }
        .nav-menu-pages{
          transform: translateX(400px);
          transition: transform 300ms 0ms ease;
          overflow: hidden;
          &.nav-active{
            transition: transform 300ms 200ms ease;
            transform: translateX(0);
          }
        }
      }
    }

    .nav-menu .nav-menu-item {
      list-style: none;
      display: inline-block;
      @include resp-calc(margin-right, 14, 14, 24, 30);
      &.contact-item, &.mobile-menu-item {
        margin-right: 0;
      }
      .nav-link {
        font-family: 'Harmonia-SemiBd';
        @include resp-calc(font-size, 12, 16, 14, 18);
        display: inline-block;
        opacity: 0.5;
        transition: opacity 200ms ease;
      }
      .nav-link.selected {
        @include resp-val(opacity, 0.0, 0.0, 1.0, 1.0);
        font-family: 'Harmonia-Bold';
      }
      .search-button {
        display: block;
        position: relative;
        z-index: 32;
        margin-right: 0;
        transition: transform 200ms 200ms ease, opacity 200ms ease;
        @include resp-calc(top, 0, 5, 5, 0);
        @include resp-val(opacity, 1.0, 1.0, 0.5, 0.5);
        &:hover{
          opacity: 1.0;
        }
        &.nav-active {
          transition: transform 200ms ease;
          transform: translateX(100px);
        }
      }
      .nav-link:hover {
        @include breakpoint(large up) {
          opacity: 1.0;
        }
      }
      &.page-display {
        // values are from-top, from-right, from-bottom, from-left
        clip-path: inset(0px 80px 0px 70px);
        transform: translateX(90px);
        transition: transform 200ms ease;
        @each $item in $nav-items {
          &.nav-active.#{nth($item, 1)}{
            @include breakpoint(small only) {
              transform: translateX(-120px + nth($item, 2));
            }
            @include breakpoint(medium only) {
              transform: translateX(-185px + nth($item, 3));
            }
          }
        }
      }
      .current-page, .left-page, .right-page {
        font-family: 'Harmonia-SemiBd';
        @include resp-calc(font-size, 12, 16, 16, 16);
        @include resp-calc(padding-right, 15, 15, 0, 0);
        display: inline-block;
        width: 70px;
        text-align: right;
      }
    }
  }

  .nav-wrapper {
    transition: transform 200ms 200ms ease;
    height: 100%;
    &.search-active{
      transition: transform 200ms ease;
      transform: translateY(-100%);
    }
  }

  .search-wrapper {
    position: absolute;
    background-color: #FFF;
    z-index: 27;
    top: -100%;
    transition: top 200ms 0ms ease;
    width: 100%;
    height: 100%;
    &.search-active{
      transition: top 200ms 200ms ease;
      top: 0;
    }
    .search-bar{
      height: 100%;
      position: relative;
      .close-button {
        position: absolute;
        @include resp-calc(top, 25, 28, 25, 45);
        @include resp-calc(right, 30, 20, 25, 30);
        cursor: pointer;
        @include resp-sprite-icon-hidpi(
          $icon-search-close-small,
          $icon-search-close-medium,
          $icon-search-close-large,
          $icon-search-close-xlarge);
      }
    }
    form {
      height: 100%;
    }
    input{
      border: none;
      box-shadow: none;
      height: 100%;
      color: #000;
      font-family: 'Harmonia-Bold';
      @include resp-calc(padding-left, 20, 20, 0, 0);
      @include resp-calc(font-size, 28, 28, 34, 65);
    }
  }

  #progress-bar {
    width: 0%;
    height: 2px;
    border-top: 1px solid #FFF;
    position: absolute;
    @include resp-calc(top, 69, 69, 69, 124);
    left: 0;
    animation-timing-function: linear;
  }

}

.headroom {
  //will-change: transform;
  transition: transform 400ms ease, background-color 600ms ease;
  .logo {
    text-indent:-9999px;
    @include resp-calc(margin-top, 20, 20, 12, 30);
    @include resp-calc(margin-left, 40, 40, 0, 0);
    position: absolute;
    background-repeat: no-repeat;
    transition: transform 200ms 200ms ease, opacity 300ms ease;
    @include breakpoint(large only) {
      margin-left: rem-px(40);
    }
    &:hover{
      @include breakpoint(large up) {
        opacity: 0.3;
      }
    }
    &:active{
      transform: translate(-2px, 2px);
    }
    &.nav-active{
      transition: transform 200ms ease;
      transform: translateX(-100px);
    }
  }
  a.nav-link {
    color: #000;
  }
}

.headroom--pinned {
  transform: translateY(0);
}

.headroom--unpinned{
  transform: translateY(-100%);
}

.headroom-top{
  background-color: transparent;
  .logo {
    @include logo-black();
  }
  .search-button {
    @include search-black();
  }
  a.nav-link {
    color: #000;
  }
  .underline {
    border-bottom-color: #000;
  }
  .lines-button {
    .lines, .lines:before, .lines:after {
      background-color: #000;
    }
  }
}

.headroom-top.white{
  background-color: transparent;
  .logo {
    @include logo-white();
  }
  .search-button {
    @include search-white();
  }
  a.nav-link {
    color: #FFF;
  }
  .current-page{
    color: #FFF;
  }
  .underline {
    border-bottom-color: #FFF;
  }
  .lines-button {
    .lines, .lines:before, .lines:after {
      background-color: #FFF;
    }
  }
}

.headroom-not-top{
  background-color: #FFFFFF;
  .logo {
    @include logo-black();
  }
  .search-button {
    @include search-black();
  }
  a.nav-link {
    color: #000;
  }
  .underline {
    border-bottom-color: #000;
  }
  .lines-button {
    .lines, .lines:before, .lines:after {
      background-color: #000;
    }
  }
}
