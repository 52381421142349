/*=[ INTRO ]==================================================================*/

.about-intro {
  @include resp-calc(padding-top, 60, 30, 80, 100);
  @include resp-calc(padding-bottom, 40, 40, 100, 120);
  background-color: #FFF;
  @include resp-val(text-align, left, center, center, center);
  .intro-1 {
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 18, 20, 24, 30);
    color: #333;
    line-height: 1.6;
  }
}

.about-carousel {
  background-color: #FFF;

  .carousel-wrapper {
    position: relative;
  }

  .slide {
    @include resp-calc(height, 700, 600, 600, 600);
    @include resp-calc(padding-top, 110, 60, 0, 0);
    position: relative;

    &:nth-child(even) {
      background-color: #FFF;
    }
    &:nth-child(odd) {
      background-color: #F7F7F7;
    }
    .slide-row, .image-wrapper {
      height: 100%;
    }
    .row-wrapper {
      @include resp-val(height, 50%, 50%, 100%, 100%);
    }
    .heading-1 {
      @include resp-val(padding-top, 20px, 20px, 180px, 140px);
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 22, 26, 30, 45);
      color: $sdblue;
      line-height: 1.2;
      @include resp-val(text-align, center, center, left, left);
    }
    .heading-2 {
      @extend .heading-1;
      color: #191919;
      padding-top: 0px;
    }
    .summary {
      @include resp-calc(padding-top, 10, 10, 20, 40);
      font-family: 'Harmonia-Regular';
      @include resp-calc(font-size, 15, 16, 17, 20);
      line-height: 1.5;
      color: #191919;
      @include resp-val(text-align, center, center, left, left);
      p{
        margin-bottom: 1rem;
      }
    }
    .image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      @include resp-val(background-position, center bottom, center bottom, center, center);
      background-size: contain;
    }
  }
}

.about-expertise {
  @include resp-calc(margin-bottom, 60, 100, 100, 100);

  .expertise-heading {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 32, 36, 45, 72);
    text-align: center;
    @include resp-calc(padding-top, 80, 80, 80, 80);
    @include resp-calc(padding-bottom, 50, 70, 70, 70);
    color: #333;
    line-height: 1.2;
  }
  .tile {
    @include tile-highlight(light);
    @include resp-calc(padding-left, 0, 14, 18, 18);
    @include resp-calc(padding-right, 0, 14, 18, 18);
    @include resp-calc(padding-bottom, 10, 30, 35, 35);
    background-color: transparent !important;
    &:hover:before {
      right: 40px !important;
    }
    &:before{
      bottom: 50px !important;
      right: 50px !important;
    }
    .thumb{
      margin: 0 !important;
      .image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        padding-bottom: 70%;
      }
    }
    .content {
      background-color: #FFF;
      width: 100%;
      @include resp-calc(height, 240, 240, 400, 480);
      @include resp-calc(padding-top, 35, 40, 55, 60);
      @include resp-calc(padding-left, 45, 20, 40, 45);
      @include resp-calc(padding-right, 45, 40, 40, 45);
      @include resp-calc(padding-bottom, 90, 90, 25, 35);
      .heading{
        font-family: 'Harmonia-Bold';
        @include resp-calc(font-size, 23, 23, 28, 30);
        color: #191919;
        @include resp-calc(padding-bottom, 15, 15, 25, 25);
        line-height: 1.2;
      }
      .summary{
        font-family: 'Harmonia-Regular';
        @include resp-calc(font-size, 15, 15, 26, 26);
        color: #191919;
        line-height: 1.5;
      }
    }
  }
}

.about-smart {
  background-color: #FFF;
  @include resp-calc(padding-top, 40, 50, 120, 120);
  @include resp-calc(padding-bottom, 70, 100, 180, 180);

  .heading{
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 36, 36, 36, 72);
    color: #333;
    @include resp-calc(padding-top, 40, 50, 0, 0);
    @include resp-calc(padding-bottom, 0, 0, 20, 0);
  }

  .text{
    @include resp-calc(padding-top, 10, 10, 10, 10);
    font-family: 'PTSerif-Regular';
    @include resp-calc(font-size, 17, 17, 19, 23);
    color: #333;
    line-height: 1.7;
  }

  .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 60%;
    box-shadow: 24px 16px 98px 0px rgba(0,0,0,0.6);
    border-style: solid;
    border-color: #000;
    @include resp-calc(border-width, 15, 20, 25, 30);
  }

}
