.section-latest, .section-clients, .section-contact{
  background-color: #F7F7F7;
}

/*=[ CAROUSEL ]==============================================================*/

@keyframes carouselRightToCenter{
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@keyframes carouselCenterToLeft{
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

@keyframes carouselCenterToRight{
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}

@keyframes carouselLeftToCenter{
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

.section-carousel{
  position: relative;
  height: 100vh;

  &.left-cursor {
    cursor: url('icon-arrow-left-white.png'), w-resize;
  }
  &.right-cursor {
    cursor: url('icon-arrow-right-white.png'), e-resize;
  }
  .video-container {
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: $sdblue;

    &.video-overlay:after{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
    }

    video {
      @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
      }
      @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
      }
    }
  }

  .link-button-inverted a {
    margin-left :0;
    margin-right: 0;
  }

  .intro-slide-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $sdblue;
  }

  .intro-slide {
    position: absolute;
    @include resp-val(top, 35%, 35%, 35%, 35%);
    left: 0;
    width: 100%;
    color: #FFF;
    padding: 0 40px;
    transition: opacity 300ms, transform 300ms;
    .intro-slide-title {
      @include resp-calc(font-size, 26, 42, 34, 64);
      font-family: 'Harmonia-Bold';
      line-height: 1.2;
    }
    .intro-slide-subtitle {
      @include resp-calc(font-size, 26, 42, 34, 64);
      font-family: 'Harmonia-Regular';
      padding-top: 10px;
      line-height: 1.2;
    }
    &.slide-hidden{
      opacity: 0;
      transform: translateY(50px);
    }
  }
  
  .container {
    display: block;
    height: 100vh;
  }

  .slide{
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 100vw;
    z-index: 14;
    animation-duration: 800ms;
    &.active{
      left: 0;
    }
  }
  .image{
    background-position: center center;
    background-size: cover;
    height: 100vh;
  }

  .caption {
    position: absolute;
    @include resp-calc(padding-left, 40, 40, 90, 0);
    @include resp-calc(padding-right, 40, 40, 90, 0);
    @include resp-val(top, 35%, 35%, 35%, 35%);
    z-index: 10;
    background: none;
    transition: opacity 300ms ease;

    &.slide-hidden{
      opacity: 0;
      .title, .bullets, .button {
        transform: translateY(50px);
      }
    }

    .title{
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 26, 42, 34, 64);
      color: #FFF;
      line-height: 1.2;
      transition: transform 300ms 50ms ease;
    }
    .subtitle{
      @extend .title;
      font-family: 'Harmonia-Regular';
      @include resp-val(width, 80vw, 80vw, 1000px, 1200px, !important);
    }
  }

  .bullets {
    position: absolute;
    z-index: 15;
    bottom: 30px;
    left: 50%;
    //transform: translate(-50%);
    transition: transform 300ms ease;
    button {
      margin-left: 0;
      border-radius: 50%;
      @include resp-calc(margin-right, 7, 10, 10, 1 5);
      @include resp-calc(width, 9, 11, 11, 18);
      @include resp-calc(height, 9, 11, 11, 18);
      background-color: rgba(255, 255, 255, 0.3);
      transition: background-color 300ms ease;
      &:hover, &.active {
        background-color: #FFF;
      }
    }
  }

  .carousel-next{
    font-family: 'Harmonia-SemiBd';
    @include resp-calc(font-size, 14, 15, 15, 20);
    position: absolute;
    line-height: 2;
    z-index: 15;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.6;
    color: #FFF;
    transition: opacity 300ms ease;
    &:hover{
      opacity: 1.0;
    }
    .arrow{
      width: 100%;
      transform: translateX(calc(50% - 10px));
      position: relative;
      .icon {
        position: absolute;
        top:0;
        //@include caret-down(10, 0, 12, #FFF)
        @include sprite-icon($icon-arrow-down-white);
      }
    }
  }
}

/*=[ INTRO ]==================================================================*/

.intro-home {
  background-color: #FFF;
  .title {
    font-family: 'Harmonia-Regular';
    line-height: 1.6;
    @include resp-calc(font-size, 18, 20, 24, 30);
    color: #333;
    background-color: #FFF;
    text-align: center;
    @include resp-calc(padding-top, 100, 100, 100, 100);
  }
  .more{
    @include resp-calc(padding-top, 10, 10, 10, 10);
    @include resp-calc(padding-bottom, 80, 80, 80, 80);
    margin: 0 auto;
    width: 210px;
  }
}


/*=[ LATEST ]=================================================================*/

.section-latest{
  @include resp-calc(padding-top, 50, 60, 80, 80);
  @include resp-calc(padding-bottom, 0, 40, 80, 120);
  padding-left: 2px;
  padding-right: 2px;

  .title{
    color:#191919;
    @include resp-calc(font-size, 32, 32, 42, 72);
    font-family: 'Harmonia-Bold';
    text-align: center;
  }

  .subtitle{
    color:#333;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 15, 17, 17, 30);
    text-align: center;
    @include resp-calc(padding-bottom, 40, 40, 60, 60);
  }

  .columns{
    padding: 0 !important;
  }

  .light h4, .light h5 {
    color: #000;
  }
  h4 {
    font-family: 'Harmonia-Bold';
    line-height: 1.1;
    @include resp-calc(font-size, 30, 20, 30, 36);
  }
  h5 {
    padding-top: 6px;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 18, 18, 20, 20);
  }
  .panel-subtitle {
    padding-top: 10px;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 20, 16, 24, 28);
  }
  .twitter{
    @include tile-highlight(light);
    //@include resp-calc(padding-bottom, 20, 15, 20, 35);
    .twitter-container{
      position: relative;
      padding-bottom: calc(50% - 15px);
    }
    .twitter-panel{
      @include resp-calc(padding, 20, 10, 20, 20);
      position: absolute;
      top: 0;
      left: 0;
    }
    .text{
      font-family: "Harmonia-Regular";
      @include resp-calc(font-size, 17, 10, 16, 22);
      color: #000;
      line-height: 1.4;
      @include resp-calc(padding-bottom, 10, 0, 10, 10);
    }
    .handle{
      @include resp-calc(font-size, 14, 10, 15, 18);
      color: #ACACAC;
      padding-top: 0;
      float: left;
    }
    .date{
      @include resp-calc(font-size, 14, 10, 15, 18);
      color: #ACACAC;
      padding-top: 0;
      float: right;
    }
    .icon{
      float: left;
      @include resp-sprite-icon(
      $icon-twitter-blue-small,
      $icon-twitter-blue-medium,
      $icon-twitter-blue-large,
      $icon-twitter-blue-xlarge);
      @include resp-calc(margin-right, 5, 5, 15, 15);
    }
  }
  .panel{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #FFF;
    position: relative;
    margin: 30px 15px;
    &:nth-child(1) {
      margin-top: 0;
    }
    &.tile {
      @include tile-highlight(dark);
    }
    &.light.tile {
      @include tile-highlight(light);
    }
    &.tile.half:before {
      right: 30px;
      bottom: 10px;
    }
    &.tile.half:hover:before {
      right: 10px;
      bottom: 10px;
    }
    .image{
      width: 100%;
      padding-bottom: 100%;
      background-position: center;
      background-size: cover;
      transform: scale(1.05) !important;
      transition: filter 200ms;
    }
    .blue {
      @extend .image;
      background-color: $sdblue;
    }
    .plain {
      @extend .image;
      background-color: #FFF;
    }
    &.half .image{
      padding-bottom: calc(50% - 15px);
    }

  }
  .panel:hover {
    .panel-content.case_study:before,
    .panel-content.article:before {
      transform: translate(0, -60%) rotate(45deg);
    }
    .panel-content.news:before {
      transform: translate(0, -85%) rotate(45deg);
    }
    .image{
      transform: scale(1.05) !important;
      filter: blur(3px);
    }
    .panel-text {
      opacity: 1;
    }
  } 
  .panel-content{
    color: #fff;
    position: absolute !important;
    @include resp-calc(padding, 20, 20, 25, 25);
    top: 0;
    left: 0;
    .panel-text {
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: opacity 200ms;
    }
    &.black {
      color: #191919;
      width: 100%;
      height: 100%;
      @include tile-highlight(light);
      h4 {
        @include resp-calc(font-size, 30, 30, 39, 55);
        line-height: 1.1;
        display: block;
        width: calc(100% - 50px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.case_study, &.article, &.news {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        background-color: #FFF;
        transition: transform 200ms;
        transform-origin: 0% 0%;
        transform: translate(-100%, -200%) rotate(45deg);
      }
      width: 100%;
      height: 100%;
      color: #191919;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
    }
    &.case_study, &.article {  
      .panel-text {
        @include resp-calc(padding-top, 40, 0, 40, 70);
        @include resp-calc(padding-left, 20, 0, 20, 40);
      }
      &:before {
        height: 200%;
      }
    }
    &.news {
      &:before {
        height: 400%;
      }
    }
    p{
      margin-left: 0px;
    }
    &.instagram {
      @include sprite-icon-hidpi($icon-instagram-tile);
      margin-left: 25px;
      margin-top: 25px;
      padding: 0;
    }
  }
  .panel.video {
    line-height: 0; // remove extra space below video
    video {
      width: 100%;
    }
  }

}

/*=[ MAKE IT ]================================================================*/

.section-makeit {
  @include resp-calc(padding-bottom, 30, 60, 60, 60);
  .heading-1 {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 32, 32, 42, 72);
    @include resp-calc(padding-top, 30, 30, 50, 50);
    color: #191919;
    line-height: 1.2;
    @include resp-val(text-align, center, center, left, left);
  }
  .summary {
    padding-top: rem-px(10);
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 15, 17, 17, 22);
    color: #7F7F7F;
    @include resp-val(text-align, center, center, left, left);
  }
  .image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

/*=[ CLIENTS ]================================================================*/

.section-clients{
  @include resp-calc(padding-top, 60, 60, 60, 60);
  @include resp-calc(padding-bottom, 40, 0, 40, 40);
  background-color: #FFF;

  .title{
    color:#191919;
    @include resp-calc(font-size, 32, 32, 42, 72);
    font-family: 'Harmonia-Bold';
    text-align: center;
  }

  .subtitle{
    color:#191919;
    font-family: 'Harmonia-Regular';
    padding-left: 20px;
    padding-right: 20px;
    @include resp-calc(font-size, 16, 17, 18, 22);
    text-align: center;
    @include resp-calc(padding-bottom, 30, 30, 30, 30);
  }

  .clients{
    padding-top: 0px;
  }
  .client{
    opacity: 0.8;
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    @include resp-val(background-size, 100%, 100%, 80%, 80%);
    background-position: center;
  }
}

/*=[ CONTACT ]================================================================*/

.section-contact {

  @include resp-calc(padding-top, 60, 60, 60, 60);
  @include resp-calc(padding-bottom, 80, 120, 170, 170);
  padding-left: 2px;
  padding-right: 2px;
  background-color: $sdblue;

  .title{
    color:#FFF;
    @include resp-calc(font-size, 32, 32, 42, 72);
    font-family: 'Harmonia-Bold';
    text-align: center;
  }

  .subtitle{
    color:#FFF;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 16, 17, 18, 22);
    text-align: center;
    @include resp-calc(padding-bottom, 20, 50, 50, 50);
  }

  .link-buttons {
    position: relative;
  }

  .button1 {
    @include breakpoint(medium) {
      position: absolute;
      right: 30px;
    }
  }

  .button2 {
    @include breakpoint(medium) {
      position: absolute;
      left: 30px;
    }
  }
}
