/*=[ FONTS ]==================================================================*/

@font-face {font-family: 'Harmonia-Regular';src: url('fonts/32F4B5_1_0.eot');src: url('fonts/32F4B5_1_0.eot?#iefix') format('embedded-opentype'),url('fonts/32F4B5_1_0.woff2') format('woff2'),url('fonts/32F4B5_1_0.woff') format('woff'),url('fonts/32F4B5_1_0.ttf') format('truetype');}


@font-face {font-family: 'Harmonia-SemiBd';src: url('fonts/32F4B5_2_0.eot');src: url('fonts/32F4B5_2_0.eot?#iefix') format('embedded-opentype'),url('fonts/32F4B5_2_0.woff2') format('woff2'),url('fonts/32F4B5_2_0.woff') format('woff'),url('fonts/32F4B5_2_0.ttf') format('truetype');}


@font-face {font-family: 'Harmonia-Bold';src: url('fonts/32F4B5_3_0.eot');src: url('fonts/32F4B5_3_0.eot?#iefix') format('embedded-opentype'),url('fonts/32F4B5_3_0.woff2') format('woff2'),url('fonts/32F4B5_3_0.woff') format('woff'),url('fonts/32F4B5_3_0.ttf') format('truetype');}

@font-face {
  font-family: 'PTSerif-Regular';
  src: url("fonts/PT_Serif-Web-Regular.ttf");
}

/*=[ CONSTANTS ]==============================================================*/

$sdblue: #00BBDC;
$sdblue-dark: #206779;

$type-black: #191919;
$type-gray: #7F7F7F;

/*=[ MEDIA QUERIES ]==========================================================*/

@include breakpoint(small) {
  html{
    font-size: 10px;
  }
}

@include breakpoint(medium) {
  html{
    font-size: 16px;
  }
}

@include breakpoint(large up) {
  html{
    font-size: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .row{
    max-width: 1200px !important;
  }
}

@media screen and (min-width: 1441px) {
  .row{
    max-width: 1440px !important;
  }
}

/*=[ FUNCTIONS ]==============================================================*/

@function rem-px($value) {
  //@return rem-calc($value);
  @return #{$value}px;
}

@function calc-padding($padding, $font-size, $line-height) {
  @return rem-px($padding - ($font-size * $line-height - $font-size)/2);
}

/*=[ MIXINS ]=================================================================*/

@mixin resp-val($property, $small, $medium, $large, $xlarge,
$important: "") {
  @include breakpoint(small) {
    #{$property}: $small #{$important};
  }
  @include breakpoint(medium) {
    #{$property}: $medium #{$important};
  }
  @include breakpoint(large) {
    #{$property}: $large #{$important};
  }
  @include breakpoint(xlarge up) {
    #{$property}: $xlarge #{$important};
  }
}

@mixin resp-calc($property, $small, $medium, $large, $xlarge,
$important: "") {
  @include breakpoint(small) {
    #{$property}: rem-px($small) #{$important};
  }
  @include breakpoint(medium) {
    #{$property}: rem-px($medium) #{$important};
  }
  @include breakpoint(large) {
    #{$property}: rem-px($large) #{$important};
  }
  @include breakpoint(xlarge up) {
    #{$property}: rem-px($xlarge) #{$important};
  }
}

@mixin resp-font-size($small, $medium, $large, $xlarge) {
  @include resp-calc(font-size, $small, $medium, $large, $xlarge);
}

@mixin resp-clip-circle($small, $medium, $large, $xlarge) {
  /*@include breakpoint(small) {
    clip-path: circle(rem-px($small) at center);
  }
  @include breakpoint(medium) {
    clip-path: circle(rem-px($medium) at center);
  }
  @include breakpoint(large) {
    clip-path: circle(rem-px($large) at center);
  }
  @include breakpoint(xlarge up) {
    clip-path: circle(rem-px($xlarge) at center);
  }*/
  border-radius: 50%;
}

@mixin resp-round-thumb($small, $medium, $large, $xlarge) {
  background-size: cover;
  background-position: center center;
  @include resp-calc(width, $small, $medium, $large, $xlarge);
  @include resp-calc(height, $small, $medium, $large, $xlarge);
  @include resp-clip-circle($small/2, $medium/2, $large/2, $xlarge/2);
}

@mixin font-padding($font-size,
$line-height: 1.4,
$padding-top: 0,
$padding-bottom: 0,
$index: 1) {

  font-size: rem-px(nth($font-size, $index));
  line-height: nth($line-height, $index);
  padding-top: calc-padding(nth($padding-top, $index),
  nth($font-size, $index), nth($line-height, $index));
  padding-bottom: calc-padding(nth($padding-bottom, $index),
  nth($font-size, $index), nth($line-height, $index));
}

@mixin resp-font-padding($font-size,
$line-height: (1.4, 1.4, 1.4, 1.4),
$padding-top: (0, 0, 0, 0),
$padding-bottom: (0, 0, 0, 0)) {

  @include breakpoint(small) {
    @include font-padding($font-size, $line-height,
    $padding-top, $padding-bottom, 1);
  }
  @include breakpoint(medium) {
    @include font-padding($font-size, $line-height,
    $padding-top, $padding-bottom, 2);
  }
  @include breakpoint(large) {
    @include font-padding($font-size, $line-height,
    $padding-top, $padding-bottom, 3);
  }
  @include breakpoint(xlarge up) {
    @include font-padding($font-size, $line-height,
    $padding-top, $padding-bottom, 4);
  }
}

@mixin sprite-icon($sprite) {
  @include sprite-percent($sprite);
  @include sprite-size($sprite);
  text-indent:-9999px;
}

@mixin sprite-icon-hidpi($sprite) {
  @include sprite-percent($sprite);
  @include sprite-size-hidpi($sprite);
  text-indent:-9999px;
}

@mixin resp-sprite-icon($small, $medium, $large, $xlarge) {
  @include breakpoint(small) {
    @include sprite-icon($small);
  }
  @include breakpoint(medium) {
    @include sprite-icon($medium);
  }
  @include breakpoint(large) {
    @include sprite-icon($large);
  }
  @include breakpoint(xlarge up) {
    @include sprite-icon($xlarge);
  }
}

@mixin resp-sprite-icon-hidpi($small, $medium, $large, $xlarge) {
  @include breakpoint(small) {
    @include sprite-icon-hidpi($small);
  }
  @include breakpoint(medium) {
    @include sprite-icon-hidpi($medium);
  }
  @include breakpoint(large) {
    @include sprite-icon-hidpi($large);
  }
  @include breakpoint(xlarge up) {
    @include sprite-icon-hidpi($xlarge);
  }
}

@mixin sprite-size($sprite) {
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

@mixin sprite-size-hidpi($sprite) {
  width: nth($sprite, 5)/2;
  height: nth($sprite, 6)/2;
}

//http://www.browniesblog.com/A55CBC/blog.nsf/dx/responsive-css-sprites.html
//x percentage width = (total image width / display image width) x 100
//y percentage width - (total image height / display image height) x 100
@mixin sprite-size-percent($sprite) {
  $sprite-width: (nth($sprite, 7) / nth($sprite, 5)) * 100%;
  $sprite-height: (nth($sprite, 8) / nth($sprite, 6)) * 100%;
  background-size:  $sprite-width  $sprite-height;
}

//x percentage pos = (x pixel pos / (total image width - display image width)) x 100
//y percentage pos - (y pixel pos / (total image height - display image height)) x 100
@mixin sprite-position-percent($sprite) {
  $sprite-x: (nth($sprite, 1) / (nth($sprite, 7) - nth($sprite, 5))) * 100%;
  $sprite-y: (nth($sprite, 2) / (nth($sprite, 8) - nth($sprite, 6))) * 100%;
  background-position: $sprite-x $sprite-y;
}

@mixin sprite-percent($sprite) {
  @include sprite-image($sprite);
  @include sprite-size-percent($sprite);
  @include sprite-position-percent($sprite);
  background-repeat: no-repeat;
}

@mixin tile-highlight($color) {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 300ms ease;
  }
  &:before{
    content: '';
    @if ($color == dark) {
      //@include sprite-percent($icon-arrow-right-white);
      //@include sprite-size($icon-arrow-right-white);
      @include resp-sprite-icon-hidpi(
        $icon-arrow-right-white-small,
        $icon-arrow-right-white-medium,
        $icon-arrow-right-white-large,
        $icon-arrow-right-white-xlarge
      );
    } @else if ($color == light) {
      //@include sprite-percent($icon-arrow-right-black);
      //@include sprite-size($icon-arrow-right-black);
      @include resp-sprite-icon-hidpi(
        $icon-arrow-right-black-small,
        $icon-arrow-right-black-medium,
        $icon-arrow-right-black-large,
        $icon-arrow-right-black-xlarge
      );
    }
    opacity: 0;
    position: absolute;
    right: 30px;
    bottom: 20px;
    z-index: 1;
    transition: opacity 400ms ease, right 400ms ease;
  }
  &:hover{
    .image {
      transform: scale(1.05);
    }
    &:before{
      opacity: 1;
      right: 20px;
    }
  }
}

@mixin remove-tile-highlight() {
  cursor: default;
  &:before{
    display: none;
  }
  &:hover{
    .image {
      transform: scale(1);
    }
  }
}

@mixin center-vertically() {
  & {
    position: relative;
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}


/*=[ CLASSES ]================================================================*/

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
  }
}

a{
  color: inherit;
  &:hover, &:visited{
    color: inherit;
  }
}

.section-link{
  color: $sdblue;
  font-family: 'Harmonia-SemiBd';
  //@include resp-calc(font-size, 14, 15, 15, 22);
  @include resp-calc(font-size, 22, 23, 24, 28);
  text-align: center;
  & a:after {
    content: ' ';
    display: inline-block;
    margin-left: 5px;
    @include resp-sprite-icon-hidpi(
      $icon-next-small,
      $icon-next-medium,
      $icon-next-large,
      $icon-next-xlarge
    );
  }
}

.clickable {
  cursor: pointer;
}

.hidden{
  display: none;
}

.halign-left {
  @include breakpoint(large up) {
    background-position-x: left;
  }
}

.halign-center {
  @include breakpoint(large up) {
    background-position-x: center;
  }
}

.halign-right {
  @include breakpoint(large up) {
    background-position-x: right;
  }
}

.valign-top {
  @include breakpoint(large up) {
    background-position-y: top;
  }
}

.valign-center {
  @include breakpoint(large up) {
    background-position-y: center;
  }
}

.valign-bottom {
  @include breakpoint(large up) {
    background-position-y: bottom;
  }
}

.halign-medium-left {
  @include breakpoint(medium down) {
    background-position-x: left;
  }
}

.halign-medium-center {
  @include breakpoint(medium down) {
    background-position-x: center;
  }
}

.halign-medium-right {
  @include breakpoint(medium down) {
    background-position-x: right;
  }
}

.valign-medium-top {
  @include breakpoint(medium down) {
    background-position-y: top;
  }
}

.valign-medium-center {
  @include breakpoint(medium down) {
    background-position-y: center;
  }
}

.valign-medium-bottom {
  @include breakpoint(medium down) {
    background-position-y: bottom;
  }
}

/*=[ MAIN ]===================================================================*/

*:focus
{
  // remove orange outline on Chrome Android
  outline: none;
}

body {
  background-color: #F7F7F7;
}

#main {
  overflow: hidden;
  pointer-events: none; // make footer clickable
}

#main > *{
  pointer-events: auto;
}

#content {
  //transform: translate3d(0,0,0); // enable hw acceleration
  backface-visibility: hidden;

  position: relative;
  z-index: 1;

  overflow: hidden;
  background-color: #F7F7F7;

  @include resp-calc(margin-bottom, 0, 0, 300, 420);
}

#content.white{
  background-color: #FFF;
}

#loader{
  display: none;
  opacity: 0.5;
  position: fixed;
  z-index: 40;
  background-image: url('loader.gif');
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 286px;
  height: 194px;
}

#overlay {
  display: none;
  position: fixed;
  @include resp-calc(top, 70, 70, 70, 125);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  opacity: 0.3;
  background-color: #000;
  &.admin-bar {
    @include resp-calc(top, 46+70, 46+70, 28+70, 28+125);
  }
}

#search-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 26;
  background-color: #000;
  transition: opacity 200ms 200ms ease;
  opacity: 0;
  pointer-events: none;
  &.search-active{
    opacity: 0.6;
    pointer-events: auto;
  }
  &.admin-bar.search-active {
    @include resp-calc(top, 46, 46, 28, 28);
  }
}

/*=[ CSS ICONS ]==============================================================*/

@mixin caret-right($top, $right, $width, $color) {
  &:before,
  &:after {
    content: '';
    display: block;
    width: rem-px($width);
    height: 1px;
    position: absolute;
    top: rem-px($top);
    right: rem-px($right);
    border-top: 2px solid $color;
    transform-origin: right;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    margin-top: -1px;
    transform: rotate(-45deg);
  }
}

@mixin caret-down($top, $right, $width, $color) {
  @include caret-right($top, $right, $width, $color);
  transform: rotate(90deg);
}

@mixin resp-caret-right(
$top-small, $top-medium, $top-large, $top-xlarge,
$right-small, $right-medium, $right-large, $right-xlarge,
$width-small, $width-medium, $width-large, $width-xlarge,
$color) {

  @include breakpoint(small) {
    @include caret-right($top-small, $right-small, $width-small, $color);
  }
  @include breakpoint(medium) {
    @include caret-right($top-medium, $right-medium, $width-medium, $color);
  }
  @include breakpoint(large) {
    @include caret-right($top-large, $right-large, $width-large, $color);
  }
  @include breakpoint(xlarge up) {
    @include caret-right($top-xlarge, $right-xlarge, $width-xlarge, $color);
  }
}

@mixin resp-caret-button(
$width-small, $width-medium, $width-large, $width-xlarge,
$height-small, $height-medium, $height-large, $height-xlarge,
$color, $hoverColor) {
  $padding-percent: 0.1;
  $caret-percent: 0.2;

  position: relative;
  text-align: left;
  vertical-align: middle;
  display: block;
  color: $color;
  border: 2px solid $color;
  @include resp-calc(width, $width-small, $width-medium,
  $width-large, $width-xlarge);
  @include resp-calc(height, $height-small, $height-medium,
  $height-large, $height-xlarge);
  @include resp-calc(line-height, $height-small - 4, $height-medium - 4,
  $height-large - 4, $height-xlarge - 4);
  @include resp-calc(padding-left,
  $width-small*$padding-percent, $width-medium*$padding-percent,
  $width-large*$padding-percent, $width-xlarge*$padding-percent);
  @include resp-caret-right(
  $height-small/2-2, $height-medium/2-2,
  $height-large/2-2, $height-xlarge/2-2,
  $width-small*$padding-percent, $width-medium*$padding-percent,
  $width-large*$padding-percent, $width-xlarge*$padding-percent,
  $height-small*$caret-percent, $height-medium*$caret-percent,
  $height-large*$caret-percent, $height-xlarge*$caret-percent,
  $color);

  transition: padding-left 100ms 50ms, color 100ms, background-color 100ms;

  .arrow {
    @include resp-caret-right(
    $height-small/2-2, $height-medium/2-2,
    $height-large/2-2, $height-xlarge/2-2,
    $width-small - $height-small*$caret-percent,
    $width-medium - $height-medium*$caret-percent,
    $width-large - $height-large*$caret-percent,
    $width-xlarge - $height-xlarge*$caret-percent,
    $height-small*$caret-percent, $height-medium*$caret-percent,
    $height-large*$caret-percent, $height-xlarge*$caret-percent,
    $hoverColor);

    &:before, &:after{
      opacity: 0;
      transition: opacity 100ms ease, right 100ms ease;
    }
  }

  &:before, &:after{
    transition: opacity 100ms 100ms ease, right 100ms 100ms ease;
  }

  &:hover{
    background-color: $color;
    color: $hoverColor;

    @include resp-calc(padding-left,
    $width-small*$padding-percent*2, $width-medium*$padding-percent*2,
    $width-large*$padding-percent*2, $width-xlarge*$padding-percent*2);

    .arrow{
      &:before, &:after{
        opacity: 1.0;
        transition: opacity 100ms 100ms ease, right 100ms 100ms ease;
        @include resp-calc(right,
        $width-small - $height-small*$caret-percent*2 - 6,
        $width-medium - $height-medium*$caret-percent*2 - 6,
        $width-large - $height-large*$caret-percent*2 - 6,
        $width-xlarge - $height-xlarge*$caret-percent*2 - 10);
      }
    }

    &:before, &:after{
      transition: opacity 100ms ease, right 100ms 1 ease;
      opacity: 0;
      right: 0;
    }
  }
}

.link-button a {
  font-family: 'Harmonia-SemiBd';
  @include resp-val(margin-left, auto, auto, 0, 0);
  @include resp-val(margin-right, auto, auto, 0, 0);
  @include resp-calc(font-size, 14, 15, 18, 18);
  @include resp-calc(margin-top, 30, 30, 30, 35);
  @include resp-caret-button(
  184, 206, 236, 236,
  46, 46, 56, 56,
  $sdblue, #FFF);
}

.link-button-inverted a {
  font-family: 'Harmonia-SemiBd';
  @include resp-val(margin-left, auto, auto, 0, 0);
  @include resp-val(margin-right, auto, auto, 0, 0);
  @include resp-calc(font-size, 14, 15, 18, 18);
  @include resp-calc(margin-top, 30, 30, 30, 35);
  @include resp-caret-button(
  184, 206, 236, 236,
  46, 46, 56, 56,
  #FFF, $sdblue);
}

/*=[ TRANSITIONS ]============================================================*/

* {
  animation-fill-mode: both;
  animation-timing-function: ease;
}

#transition-mask {
  transform: translateX(-100%);
  position: fixed;
  z-index: 50;
  background-color: #F7F7F7;
  width: 100vw;
  height: 100vh;
}

@keyframes slideLeftStart{
  from {
    opacity: 1;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideLeftEnd{
  from {
    opacity: 1;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRightStart{
  from {
    opacity: 1;
    transform: translateX(-100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRightEnd{
  from {
    opacity: 1;
    transform: translateX(-100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);

  }
}

@keyframes zoomInStart{
  from {
    opacity: 1;
    transform: scale(1, 1);
  }
  to {
    opacity: 0;
    transform: scale(1.05, 1.05);
  }
}

@keyframes zoomImageInEnd{
  from {
    opacity: 0;
    transform: scale(1, 1);
  }
  to {
    opacity: 1;
    transform: scale(1.05, 1.05);
  }
}

@keyframes zoomTextInEnd{
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoomOutStart{
  from {
    opacity: 1;
    transform: scale(1, 1);
  }
  to {
    opacity: 0;
    transform: scale(0.95, 0.95);
  }
}

@keyframes zoomOutEnd{
  from {
    opacity: 0;
    transform: scale(1.05, 1.05);

  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes fadeStart{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeEnd{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*=[ BEHAVIOURS ]=============================================================*/

.fadeable {
  opacity: 0.0;
  transition: opacity 2s ease;
  &.revealed{
    opacity: 1.0;
  }
}

.text-button{
  transition: color 300ms ease;
  &:hover{
    color: $sdblue;
  }
}

@mixin growing-underline($color, $delay) {
  cursor: pointer;
  &:before {
    content: '';
    pointer-events: none;
    display: block;
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid $color;
    transition: width $delay ease;
  }
  &:hover:before{
    width: 100%;
  }
}


/*=[ NEXT ]===================================================================*/

.section-next{
  @include resp-calc(padding-top, 0, 70, 70, 70);
  @include resp-calc(height, 160, 200, 240, 260);
  background-color: #191919;
  text-align: center;
  transition: background-color 400ms ease;
  cursor: pointer;
  a.main-link{
    color:#FFF;
    @include resp-calc(font-size, 32, 34, 42, 52);
    font-family: 'Harmonia-Bold';
    transition: color 400ms ease;
  }
  a.sec-link{
    color:$sdblue;
    @include resp-calc(font-size, 15, 15, 15, 15);
    font-family: 'Harmonia-SemiBd';
    transition: color 400ms ease;
    &:after {
      content: ' ';
      display: inline-block;
      margin-left: 5px;
      position: relative;
      top: 1px;
      @include resp-sprite-icon-hidpi(
        $icon-next-small,
        $icon-next-medium,
        $icon-next-large,
        $icon-next-xlarge
      );
    }
  }
  &:hover{
    background-color: #FFF;
    a.main-link{
      color:#191919;
    }
  }
}

/*=[ NEXT ]===================================================================*/

.section-back{
  .clickable {
    text-align: center;
    @include resp-calc(height, 100, 100, 100, 120);
    @include resp-calc(line-height, 100, 100, 100, 120);
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 16, 15, 16, 18);
    background-color: #FFF;
    transition: background-color 300ms ease;
    &:hover{
      background-color: $sdblue;
      color: #FFF;
      a:before{
        @include resp-sprite-icon-hidpi(
          $icon-arrow-right-white-small,
          $icon-arrow-right-white-medium,
          $icon-arrow-right-white-large,
          $icon-arrow-right-white-xlarge
        );
      }
    }
    a:before{
      content: '';
      display: inline-block;
      margin-right: 5px;
      position: relative;
      @include resp-calc(top, 3, 3, 5, 5);
      transform: scaleX(-1);
      filter: FlipH;
      @include resp-sprite-icon-hidpi(
        $icon-arrow-right-black-small,
        $icon-arrow-right-black-medium,
        $icon-arrow-right-black-large,
        $icon-arrow-right-black-xlarge
      );
    }
  }
}

/*=[ 404 ]====================================================================*/

body.error404{
  background-color: $sdblue;
}

.not-found{
  padding-top: 300px;
  background-color: $sdblue;
  .text{
    color: #FFF;
    font-family: 'Harmonia-SemiBd';
    font-size: rem-calc(18);
  }
}

/*=[ TYPED.JS ]===============================================================*/

.typed-cursor{
	opacity: 1;
	-webkit-animation: blink 0.7s infinite;
	-moz-animation: blink 0.7s infinite;
	animation: blink 0.7s infinite;
}
@keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}
@-webkit-keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}
@-moz-keyframes blink{
	0% { opacity:1; }
	50% { opacity:0; }
	100% { opacity:1; }
}
