/*=[ FOOTER ]=================================================================*/

.footer {
  @include breakpoint(large up) {
    position: fixed;
    z-index: 0 !important;
    opacity: 0.0;
    bottom: 0;
  }
  @include resp-calc(height, 530, 530, 300, 420);
  width: 100%;
  background-color: #333333;
  &.visible {
    opacity: 1.0;
  }

  .links {
    @include resp-calc(padding-top, 30, 40, 70, 135);
    @include resp-calc(height, 245, 250, 150, 216);
    @include breakpoint(large up) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  .pages{
    ul {
      @include resp-calc(padding-left, 40, 40, 0, 0);
    }
    li {
      font-family: 'Harmonia-Regular';
      @include resp-val(display, block, block, inline-block, inline-block);
      @include resp-calc(font-size, 18, 18, 15, 18);
      @include resp-calc(padding-bottom, 20, 20, 0, 0);
      color: #7F7F7F;
      padding-right: 40px;
    }
    &.pages-2{
      @include resp-calc(padding-top, 30, 0, 0, 0);
    }
  }
  .social{
    @include resp-val(text-align, center, center, right, right);
    @include resp-calc(margin-top, 30, 30, 0, 0);
    @include resp-calc(padding-top, 30, 30, 0, 0);
    @include breakpoint(medium down) {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
    .icon{
      display: inline-block;
      text-indent: 9999px !important;
      margin-right: rem-px(25);
      position: relative;
    }
    .icon:after{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity 300ms ease;
    }
    .icon:hover{
      &:after {
        opacity: 1;
      }
    }
    .icon.facebook{
      @include resp-sprite-icon-hidpi(
      $footer-facebook-small,
      $footer-facebook-medium,
      $footer-facebook-large,
      $footer-facebook-xlarge);
    }
    .icon.twitter{
      @include resp-sprite-icon-hidpi(
      $footer-twitter-small,
      $footer-twitter-medium,
      $footer-twitter-large,
      $footer-twitter-xlarge);
    }
    .icon.instagram{
      @include resp-sprite-icon-hidpi(
      $footer-instagram-small,
      $footer-instagram-medium,
      $footer-instagram-large,
      $footer-instagram-xlarge);
    }
    .icon.linkedin{
      @include resp-sprite-icon-hidpi(
      $footer-linkedin-small,
      $footer-linkedin-medium,
      $footer-linkedin-large,
      $footer-linkedin-xlarge);
    }
    .icon.medium{
      margin-right: 0;
      @include resp-sprite-icon-hidpi(
      $footer-medium-small,
      $footer-medium-medium,
      $footer-medium-large,
      $footer-medium-xlarge);
    }
    .icon.facebook:after{
      @include resp-sprite-icon-hidpi(
      $footer-facebook-hover-small,
      $footer-facebook-hover-medium,
      $footer-facebook-hover-large,
      $footer-facebook-hover-xlarge);
    }
    .icon.twitter:after{
      @include resp-sprite-icon-hidpi(
      $footer-twitter-hover-small,
      $footer-twitter-hover-medium,
      $footer-twitter-hover-large,
      $footer-twitter-hover-xlarge);
    }
    .icon.instagram:after{
      @include resp-sprite-icon-hidpi(
      $footer-instagram-hover-small,
      $footer-instagram-hover-medium,
      $footer-instagram-hover-large,
      $footer-instagram-hover-xlarge);
    }
    .icon.linkedin:after{
      @include resp-sprite-icon-hidpi(
      $footer-linkedin-hover-small,
      $footer-linkedin-hover-medium,
      $footer-linkedin-hover-large,
      $footer-linkedin-hover-xlarge);
    }
    .icon.medium:after{
      margin-right: 0;
      @include resp-sprite-icon-hidpi(
      $footer-medium-hover-small,
      $footer-medium-hover-medium,
      $footer-medium-hover-large,
      $footer-medium-hover-xlarge);
    }
  }
  .brand {
    text-align: center;
    color: #FFF;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 20, 18, 18, 20);
    @include resp-calc(padding-top, 60, 70, 50, 50);
    line-height: 1.8;
  }
  .copyright{
    text-align: center;
    font-family: 'Harmonia-Regular';
    color: #7F7F7F;
    @include resp-calc(font-size, 16, 16, 16, 16);
  }
}
