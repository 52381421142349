/*=[ INTRO ]==================================================================*/

.intro-video-hero {
	position: relative;
	height: 100vh;
	overflow: hidden;
	video {
		position: absolute;
		top: 0px;
		left: 0px;
		min-width: 100%;
		min-height: 100%;
		width: auto;
		height: auto;
	}
	.overlay{
		background-color: rgba(0, 0, 0, 0.3);
		position: absolute;
		background-position: center;
		background-size: cover;
		top: 0;
		z-index: 15;
		width: 100%;
		height: 100%;
		@include breakpoint(large up) {
			background-image: none !important;
		}

		h1 {
			margin-top: 40vh;
			text-align: center;
			color: #FFFFFF;
			font-family: 'Harmonia-Bold';
			@include resp-calc(font-size, 44, 52, 54, 82);
			@include resp-val(line-height, 1, 1.2, 1.2, 1.2);
		}
		h2{
			font-family: 'Harmonia-Regular';
			@include resp-calc(font-size, 15, 17, 20, 30);
			text-align: center;
			color: #FFFFFF;
			@include resp-calc(padding-top, 10, 0, 0, 0);
		}
		&:after {
			position: absolute;
			bottom: 30px;
			left: calc(50% - 10px);
			@include sprite-icon($icon-arrow-down-white);
			display: block;
			content: '';
		}
	}
}

.section-description {
	@include resp-calc(padding-top, 60, 30, 80, 100);
	@include resp-calc(padding-bottom, 40, 40, 100, 120);
	background-color: #FFF;
	h3 {
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 18, 20, 24, 30);
		line-height: 1.6;
		color: #333;
		text-align: center;
	}
}

/*=[ CAROUSEL ]===============================================================*/

.carousel-people{
	@include resp-calc(padding-top, 80, 80, 80, 120);
	background-color: #333333;
	@include resp-calc(padding-bottom, 40, 40, 70, 100);

	.heading{
		text-align: center;
		color: #FFF;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 32, 36, 45, 72);
		line-height: 1.3;
		.blue {
			color: $sdblue;
		}
	}
	.subheading{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 13, 17, 17, 24);
		color: #CCCCCC;
		text-align: center;
		@include resp-calc(padding-top, 15, 15, 20, 40);
		@include resp-calc(padding-bottom, 40, 70, 80, 80);
	}
	.carousel {
		.thumb{
			width: 100%;
			padding-bottom: 100%;
			background-size: cover;
			background-position: center;
		}
		.thumb-wrapper {
			padding: 15px;
		}
		&.carousel-large{
			display: flex;
			.carousel-column{
				width: 33.333%;
				display: flex;
				flex-wrap: wrap;
			}
			.thumb-wrapper {
				width: 50%;
			}
			.thumb-wrapper.thumb-3,
			.thumb-wrapper.thumb-4,
			.thumb-wrapper.thumb-9{
				width: 100%;
				.thumb{
					padding-bottom: 100%;
				}
			}
		}
		&.carousel-medium{
			.carousel-column{
				width: 100%;
			}
			.thumb-4-wrapper{
				@extend .medium-4;
				padding: 0;
			}
			.thumb-wrapper.thumb-2,
			.thumb-wrapper.thumb-3,
			.thumb-wrapper.thumb-8,
			.thumb-wrapper.thumb-9{
				@extend .medium-4;
			}
			.thumb-wrapper.thumb-1,
			.thumb-wrapper.thumb-6,
			.thumb-wrapper.thumb-7{
				@extend .medium-8;
				.thumb{
					padding-bottom: 100%;
				}
			}
		}
		&.carousel-small{
			.thumb-wrapper{
				width: 50%;
				float: left;
			}
			.thumb-wrapper.thumb-1,
			.thumb-wrapper.thumb-4,
			.thumb-wrapper.thumb-7{
				width: 100%;
				float: none;
				.thumb{
					padding-bottom: 100%;
				}
			}
		}
	}
	.more{
		text-align: center;
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(font-size, 13, 15, 17, 24);
		color: #999999;
		@include resp-calc(padding-top, 35, 50, 70, 80);
		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}

/*=[ STUDIOS ]================================================================*/

.section-studios{
	@include resp-calc(padding-top, 50, 50, 100, 100);
	@include resp-calc(padding-bottom, 50, 50, 50, 50);
	.heading{
		text-align: center;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 32, 36, 45, 72);
		color: #333;
		line-height: 1.5;
	}
	.subheading{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 15, 15, 17, 24);
		color: #333;
		text-align: center;
		@include resp-calc(padding-bottom, 40, 40, 80, 80);
	}
	.location{
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 26, 26, 40, 49);
		color: #FFF;
		@include resp-calc(padding-left, 20, 100, 150, 170);
		@include resp-calc(padding-top, 80, 90, 170, 170);
	}
	.directions a{
		@include resp-calc(margin-left, 20, 100, 150, 170);
		@include resp-calc(margin-top, 0, 0, 10, 10);
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(font-size, 14, 15, 24, 28);
		@include resp-caret-button(
		161, 164, 257, 308,
		38, 46, 72, 87,
		#FFF, $sdblue);
	}
	.branch{
		@include resp-calc(height, 210, 260, 480, 480);
		background-position: center;
		background-size: cover;
		//filter: brightness(0.7) contrast(140%);
		@include resp-calc(margin-top, 20, 0, 0, 0);
	}
	.address{
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(font-size, 13, 13, 20, 24);
		color: #999;
		@include resp-calc(padding-left, 20, 100, 150, 170);
		@include resp-calc(padding-top, 20, 20, 30, 40);
		@include resp-calc(padding-bottom, 0, 50, 70, 90);
	}
}

/*=[ TEAM ]===================================================================*/

@keyframes fadeTeamTile {
	from {
		opacity: 0.0;
		transform: translateX(20px);
	}
	to {
		opacity: 1.0;
		transform: translateX(0px);
	}
}

@keyframes bulletFill {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

.section-team {
	@include resp-calc(padding-top, 80, 60, 100, 120);
	@include resp-calc(padding-bottom, 2, 2, 120, 200);
	background-color: #FFF;
	.heading{
		text-align: center;
		color: #333333;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 32, 36, 45, 72);
	}
	.subheading{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 13, 15, 17, 24);
		color: #4C4C4C;
		text-align: center;
		@include resp-calc(padding-bottom, 60, 50, 50, 80);
	}
	.bullets {
		text-align: center;
		@include resp-calc(padding-bottom, 0, 30, 50, 60);
		.bullet {
			display: inline-block;
			position: relative;
			list-style: none;
			width: 13px;
			height: 13px;
			margin-right: 10px;
			background-color: #E6E6E6;
			transition: background-color 300ms ease;
			&:hover {
				cursor: pointer;
				background-color: $sdblue;
			}
			&.active {
				background-color: $sdblue;
			}
			.fill {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 100%;
				background-color: $sdblue;
			}
		}
	}
	.team-wrapper {
		position: relative;
		padding: 0;
	}
	@include breakpoint(small only) {
		.team-element:nth-child(n+11):nth-child(-n+16) {
			display: none;
		}
	}
	.team{
		margin: 15px;
		@include tile-highlight(dark);
		transition: opacity 1000ms ease;
		opacity: 1.0;
		animation-duration: 500ms;
		position: relative;
		&.team-1, &.team-5, &.team-9, &.team-13 {
			animation-delay: 0ms;
		}
		&.team-2, &.team-6, &.team-10, &.team-14 {
			animation-delay: 50ms;
		}
		&.team-3, &.team-7, &.team-11, &.team-15 {
			animation-delay: 100ms;
		}
		&.team-4, &.team-8, &.team-12, &.team-16 {
			animation-delay: 150ms;
		}
		.image{
			padding-bottom: 100%;
		}
		.info {
			display: none;
		}
		&.inactive {
			opacity: 0.3;
			@include remove-tile-highlight();
		}
		&.selected {
			@include remove-tile-highlight();
			&:after{
				opacity: 1;
			}
		}
		&:after{
			content: '';
			display: block;
			position: absolute;
			@include resp-sprite-icon(
				$icon-team-close-small,
				$icon-team-close-medium,
				$icon-team-close-large,
				$icon-team-close-xlarge);
			top: 0;
			left: 0;
			cursor: pointer;
			opacity: 0;
			transition: opacity 300ms ease;
		}
	}
	.overlay-wrapper {
		z-index: 5;
		position: absolute;
		top: 0;
		display: none;
	}
	.overlay {
		padding-bottom: calc(100% - 4px);
		background-color: $sdblue;
		margin: 2px;
		position: relative;
		.overlay-content {
			@include resp-calc(padding-top, 40, 20, 40, 60);
			@include resp-calc(padding-left, 25, 25, 40, 45);
			@include resp-calc(padding-right, 25, 25, 40, 45);
			position: absolute;
			top: 0;
			left: 0;
			.name {
				font-family: 'Harmonia-Bold';
				@include resp-calc(font-size, 23, 23, 30, 36);
				color: #FFF;
			}
			.position, .office {
				@extend .name;
				color: #333;
				line-height: 1.2;
			}
			.bio {
				font-family: 'Harmonia-Regular';
				@include resp-calc(font-size, 17, 17, 26, 31);
				color: #FFF;
				@include resp-calc(padding-top, 40, 30, 50, 50);
			}
		}
		.more {
			font-family: 'Harmonia-SemiBd';
			@include resp-calc(font-size, 15, 15, 22, 26);
			color: #FFF;
			position: absolute;
			@include resp-calc(bottom, 40, 20, 30, 40);
			left: 0;
			@include resp-calc(margin-left, 25, 25, 40, 45);
			line-height: 1;
			@include growing-underline(#FFF, 300ms);

		}
	}
	.load-more {
		font-family: 'Harmonia-SemiBd';
		font-size: rem-px(15);
		color: #666;
		width: 100%;
		text-align: center;
		line-height: rem-px(70);
	}

}



/*=[ HIRING ]=================================================================*/

.section-hiring {
	background-color: $sdblue;
	.heading{
		@include resp-calc(padding-top, 70, 70, 120, 150);
		color: #FFFFFF;
		text-align: center;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 32, 36, 45, 72);
		line-height: 1.5
	}
	.subheading{
		color: #FFFFFF;
		text-align: center;
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 13, 15, 17, 24);
		@include resp-calc(padding-bottom, 50, 30, 50, 100);
	}
	.branch{
		border-top: solid 1px #00A9C7;
		padding-top: 16px;
		@include resp-calc(padding-bottom, 40, 40, 75, 120);
	}
	.city {
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 22, 18, 34, 49);
		@include resp-calc(padding-top, 0, 0, 16, 16);
		color: #333;
		position: relative;
		z-index: 10;
	}
	.positions {
		ul li {
			list-style: none;
			font-family: 'Harmonia-Regular';
			@include resp-calc(font-size, 16, 16, 30, 35);
			@include resp-calc(line-height, 40, 35, 75, 100);
			color: #FFF;
			cursor: pointer;
			position: relative;
			a{
				display: block;
				color: #FFF;
				position: relative;
				z-index: 10;
			}
			.highlight{
				background-color: $sdblue;
				transition: background-color 400ms linear;
				width: 200vw;
				height: 100%;
				position: absolute;
				top: 0px;
				left: -100vw;
				z-index: 0;
			}
		}
		ul li:hover{
			.highlight{
				background-color: #00A3C0;
			}
		}
	}
	.more {
		border-top: solid 1px #00A9C7;
		padding-top: 30px;
		@include resp-calc(padding-top, 20, 20, 20, 30);
		@include resp-calc(padding-bottom, 50, 30, 120, 130);
		p {
			font-family: 'Harmonia-SemiBd';
			@include resp-calc(font-size, 13, 13, 15, 18);
			color: #FFF;
		}
		a{
			color: #FFF;
			text-decoration: underline;
		}
	}
}
