/*=[ BIO ]====================================================================*/

.section.team-profile {
  background-color: #FFF;
  @include breakpoint(large only) {
    padding-left: rem-px(40);
  }
  
  .titles{
    @include resp-calc(padding-left, 40, 40, 0, 0);
  }
  h1{
    font-family: "Harmonia-Bold";
    @include resp-calc(font-size, 24, 34, 42, 72);
    @include resp-calc(margin-top, 140, 180, 190, 270);
    line-height: 1;
    color: #191919;
  }

  h2 {
    font-family: "Harmonia-Regular";
    @include resp-calc(font-size, 16, 21, 26, 32);
    color: #191919;
  }

  .main-section {
    @include resp-calc(margin-top, 20, 75, 60, 80);
    @include resp-calc(margin-bottom, 0, 0, 95, 70);
  }
  .thumb-wrapper{
    @include breakpoint(medium only) {
      display: flex;
      align-items: flex-end;
    }
  }
  .thumb{
    @include resp-val(width, 100%, 40%, 380px, 468px);
    @include resp-val(height, auto, auto, 380px, 468px);
    @include resp-val(padding-bottom, 100%, 40%, 0, 0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include resp-calc(margin-left, 0, 100, 0, 0);
    @include resp-calc(margin-bottom, 15, 50, 50, 55);
  }
    .introduction{
      font-family: "Harmonia-Regular";
      @include resp-calc(font-size, 22, 21, 26, 32);
      color: #191919;
      @include resp-calc(margin-top, 90, 0, 0, 0);
      @include resp-calc(margin-left, 20, 100, 110, 120);
      @include resp-calc(margin-right, 20, 100, 0, 0);
      @include resp-calc(margin-bottom, 60, 80, 60, 110);
    }
    .content {
      font-family: "PTSerif-Regular";
      @include resp-calc(font-size, 17, 17, 19, 23);
      color: #191919;
      @include resp-calc(margin-left, 20, 100, 110, 120);
      @include resp-calc(margin-right, 20, 100, 0, 0);
      line-height: 1.7;
      p{
        @include resp-calc(margin-bottom, 65, 70, 40, 60);
      }
    }
  .info-panel{
    @include resp-calc(padding-left, 20, 20, 0, 0);
     @include resp-calc(margin-bottom, 0, 50, 0, 0);
    .subheading{
      font-family: "Harmonia-Bold";
      @include resp-calc(font-size, 18, 16, 20, 23);
      @include resp-calc(margin-top, 30, 20, 20, 20);
      color: #333;
    }
    .info{
      font-family: "Harmonia-Regular";
       @include resp-calc(font-size, 18, 16, 20, 23);
      color: #191919;
    }
    .social{
        text-align: left !important;
        @include resp-val(text-align, center, right, right, right);
        @include resp-calc(padding-right, 0, 20, 20, 20);

        .icon{
            display: inline-block;
            margin-right: rem-px(25);
        }
        .icon.facebook{
            @include resp-sprite-icon(
                $icon-facebook-xlarge,
                $icon-facebook-xlarge,
                $icon-facebook-xlarge,
                $icon-facebook-xlarge);
        }
        .icon.twitter{
            @include resp-sprite-icon(
                $icon-twitter-xlarge,
                $icon-twitter-xlarge,
                $icon-twitter-xlarge,
                $icon-twitter-xlarge);
        }
        .icon.instagram{
            @include resp-sprite-icon(
                $icon-instagram-xlarge,
                $icon-instagram-xlarge,
                $icon-instagram-xlarge,
                $icon-instagram-xlarge);
        }
        .icon.linkedin{
            @include resp-sprite-icon(
                $icon-linkedin-xlarge,
                $icon-linkedin-xlarge,
                $icon-linkedin-xlarge,
                $icon-linkedin-xlarge);
        }
      }
    }
}
