/*=[ HERO ]===================================================================*/

#content.hero {
  background-color: #333;
}

.section-hero {
  position: fixed;
  top: 0;
  width: 100%;
  pointer-events: none; // make footer clickable

  &.hero .hero-video {
    @extend .show-for-large;
  }
  &.light h1, &.light h2, &.light h3, &.light .source{
    color: #191919;
  }
  video {
    //position: absolute;
    //top: 0px;
    //left: 0px;
    //min-width: 100%;
    //min-height: 100%;
    //width: auto;
    //height: auto;
    min-width: 100%;
    height: 100vh;
  }
  .video-container:after{
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .hero-image {
    background-size: cover;
    z-index: 10;
    height: 100vh;
  }
  .titles {
    position: absolute;
    top: 0;
    z-index: 10;
    @include resp-calc(padding-left, 40, 40, 0, 0);
    @include resp-calc(padding-top, 100, 120, 130, 215);
  }
  h1, .source{
    color: #FFFFFF;
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 24, 34, 42, 60);
    line-height: 1.2;
  }
  h2 {
    color: #FFFFFF;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 24, 34, 42, 60);
    line-height: 1.2;
    @include resp-val(width, 80vw, 80vw, 1000px, 1200px, !important);
  }
  h3 {
    @include resp-calc(padding-top, 30, 50, 20, 35);
    color: #FFFFFF;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 16, 20, 24, 32);
  }
  &.expertise h3 {
    padding-top: 0;
  }
  .titles {
    @include breakpoint(large only) {
      padding-left: rem-px(40);
    }
  }
}

.section-hero-nothumb {
  background-color: #F7F7F7;
  @include resp-calc(padding-top, 100, 120, 175, 235);
  @include resp-calc(padding-bottom, 30, 30, 45, 80);
  @include resp-calc(padding-left, 40, 40, 0, 0);
  h1, .source {
    @include resp-calc(font-size, 24, 34, 42, 72);
    line-height: 1.2;
  }
  h1, .source {
    font-family: 'Harmonia-Regular';
    color: #191919;
  }
  .source{
    font-family: 'Harmonia-Bold';
  }
  h3 {
    @include resp-calc(padding-top, 10, 10, 20, 35);
    color: #191919;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 16, 20, 24, 32);
  }
}

/*=[ ARTICLE ]================================================================*/

.below-fold {
  background-color:  #F7F7F7;

  .newsletter-link {
    padding-top: 20px;
    padding-bottom: 20px;
    @include resp-calc(margin-top, 20, 20, 40, 40);
    background-color: #FFF;
  }
}

.section-intro {
  z-index: 25;
  position: relative;
  &.portfolio {
    @include resp-val(padding-top, 100vh, 100vh, 76vh, 76vh);
    &.section-intro-nothumb {
      padding-top: 20vh;
    }
    .wrapper {
      background-color: #FFF;
    }
    .intro-text p, .name, .value {
      color: #333;
    }
    .summary-wrapper {
      border-bottom: 1px solid #E6E6E6;
    }
  }
  &.hero {
    background-color: #333333;
    margin-top: 100vh;
    .wrapper {
      background-color: inherit;
    }
    .summary-wrapper {
      border-bottom: 1px solid #9B9B9B;
    }
  }
  .wrapper {
    padding-left: 0;
    padding-right: 0;
    @include resp-calc(padding-bottom, 70, 80, 90, 140);
  }
  .summary-wrapper {
    @include resp-calc(height, 120, 170, 210, 214);
  }
  .summary ul {
    @include resp-calc(padding-top, 35, 40, 60, 60);
    @include resp-calc(padding-left, 20, 100, 0, 0);
    @include resp-calc(padding-right, 20, 100, 0, 0);
    list-style: none;
    li {
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
    }
    .name {
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 18, 14, 18, 22);
    }
    .value {
      font-family: 'Harmonia-Regular';
      @include resp-calc(font-size, 16, 16, 18, 22);
      line-height: 1.2;
    }
    .value a {
      @extend .value;
      border-bottom: 1px solid $sdblue;
    }
  }
  .a2a_kit {
    padding-bottom: 20px;
  }
  .intro-text {
    @include resp-calc(margin-top, 70, 80, 90, 140);
    @include resp-calc(padding-left, 20, 100, 0, 0);
    @include resp-calc(padding-right, 20, 100, 0, 0);
    p {
      font-family: 'Harmonia-Regular';
      line-height: 1.6;
      @include resp-calc(font-size, 21, 21, 24, 30);
      padding-bottom: 20px;
      a{
        border-bottom: 1px solid $sdblue;
      }
    }
    h4 {
      @include resp-calc(padding-bottom, 24, 24, 20, 40);
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 22, 21, 26, 32);
      &:nth-child(n+2) {
        @include resp-calc(padding-top, 60, 60, 60, 100);
      }
    }
  }
  .authors {
    display: flex;
    justify-content: center;
  }
  .author {
    display: inline-block;
    @include resp-calc(width, 150, 200, 300, 300);
    .author-thumb {
      margin-left: auto;
      margin-right: auto;
      @include resp-round-thumb(44, 60, 70, 86);
      @include resp-calc(margin-top, 20, 25, 35, 25);
    }
    .author-name {
      padding-top: 10px;
      font-family: 'Harmonia-SemiBd';
      @include resp-calc(font-size, 12, 16, 18, 22);
      color: #000;
      line-height: 1.3;
      text-align: center;
    }
    .author-position {
      font-family: 'Harmonia-Regular';
      @include resp-calc(font-size, 12, 16, 18, 22);
      color: #191919;
      line-height: 1.3;
      text-align: center;
    }
  }
}

.section-video, .section-image {
  &.portfolio .padding {
    position: relative;
    @extend .row, .small-12;
    @include resp-calc(height, 80, 60, 60, 70);
    background-color: #FFF;
  }
  .caption-wrapper {
    background-color: #FFF;
    padding: 0;
  }
  .caption {
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 12, 14, 18, 18);
    color: #999;
    padding-top: 20px;
    @include resp-calc(padding-left, 20, 100, 0, 0);
    @include resp-calc(padding-right, 20, 100, 0, 0);
  }
}

.section-video {
  .flex-video {
    margin-bottom: 0;
  }
}

.section-article,
.section-video {
  z-index: 25;
  position: relative;
  .wrapper {
    background-color: #FFF;
    padding-left: 0;
    padding-right: 0;
  }
}

.section-image {
  .grid-image-wrapper {
    background-color: #FFF;
    padding: 0;
  }
  .grid-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .full-bleed-image {
    width: 100%;
    padding-bottom: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    &.overlay:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.3;
      z-index: 1;
    }
  }
  .overlay-text{
    position: relative;
    z-index: 2;
    padding-top: rem-px(90);
    color: #FFF !important;
  }
}

.section-article, .overlay-text {
  .paragraph-wrapper {
    @include resp-calc(padding-top, 30, 30, 30, 70);
    @include resp-calc(padding-bottom, 30, 30, 30, 70);
    @include resp-calc(padding-left, 20, 100, 0, 0);
    @include resp-calc(padding-right, 20, 100, 0, 0);
    color: #333;
  }
  h4 {
    @include resp-calc(padding-bottom, 10, 10, 10, 20);
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 22, 21, 26, 32);
    color: inherit;
    &:nth-child(n+2) {
      @include resp-calc(padding-top, 60, 60, 60, 100);
    }
  }
  p, li {
    font-family: 'PTSerif-Regular';
    @include resp-calc(font-size, 17, 17, 19, 23);
    line-height: 1.75;
    color: inherit;
  }
  p, ul, ol {
    padding-bottom: 30px;
  }
  li {
    @include resp-calc(margin-left, 30, 40, 60, 60);
  }
  a{
    color: inherit;
    border-bottom: 1px solid $sdblue;
  }
  .date {
    font-family: 'Harmonia-Regular';
    color: inherit;
    @include resp-calc(font-size, 12, 12, 18, 22);
  }
  .centered-text p, .centered-text h4 {
    text-align: center;
  }
}

.carousel-article{

  &.portfolio.normal-width .carousel-row,
  &.full-width .carousel-row{
    @extend .row, .collapse;
  }
  &.portfolio.normal-width .carousel-wrapper,
  &.fulll-width .carousel-wrapper{
    @extend .small-12, .column;
  }
  &.hero.normal-width .carousel-wrapper {
    width: 100%;
  }
  &.hero.full-width .slick-dots li button::before {
    background-color: #FFF;
  }
  .carousel-wrapper {
    background-color: #FFF;
    @include resp-calc(padding-top, 80, 60, 60, 70);
    @include resp-calc(padding-bottom, 80, 60, 60, 70);
  }
  .carousel-headings{
    text-align: center;
    .heading {
      @include resp-calc(padding-top, 10, 30, 20, 60);
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 22, 21, 26, 32);
    }
    .subheading {
      font-family: 'PTSerif-Regular';
      @include resp-calc(padding-top, 10, 10, 10, 20);
      @include resp-calc(padding-bottom, 40, 60, 60, 80);
      @include resp-calc(font-size, 17, 17, 19, 19);
    }
  }
  .carousel-caption{
    text-align: center;
    .caption-title {
      padding: 20px 20px 0 20px;
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 16, 16, 22, 22);
    }
    .caption-text {
      font-family: 'Harmonia-Regular';
      @include resp-calc(font-size, 16, 16, 22, 22);
    }
  }
  .slick-carousel {
    padding-bottom: rem-px(20);
    img {
      outline: none;
      width: 100%;
    }
    .slick-slide {
      @include resp-calc(width, 334, 420, 640, 960);
      margin-left: 2px;
      margin-right: 2px;
      &:focus {
        outline: none;
      }
      &.left-cursor {
        cursor: url('icon-arrow-left-white.png'), w-resize;
      }
      &.right-cursor {
        cursor: url('icon-arrow-right-white.png'), e-resize;
      }
    }
    .slick-dots li {
      @include resp-calc(width, 6, 6, 10, 10);
      @include resp-calc(height, 6, 6, 10, 10);
    }
    .slick-dots li button::before {
      @include resp-calc(width, 6, 6, 10, 10);
      @include resp-calc(height, 6, 6, 10, 10);
      content: '';
      background-color: #000;
    }
  }
}

.section-mosaic{
  &.portfolio .mosaic-wrapper{
    background-color: #FFF;
    @include resp-calc(padding-top, 80, 60, 60, 70);
    @include resp-calc(padding-bottom, 80, 60, 60, 70);
    @extend .row;
    &.mosaic-3 .thumb-3 {
      @extend .small-12;
    }
  }
  &.portfolio .thumb-wrapper{
    @extend .small-6;
  }
  &.portfolio.mosaic-6 .thumb-wrapper{
    @extend .small-4;
  }
  &.hero .thumb-wrapper{
    width:50%;
  }
  &.hero.mosaic-6 .thumb-wrapper{
    width:33.33%;
  }
  .thumb-wrapper {
    padding: 2px;
  }
  .thumb{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 100%;
    &.wide-images {
      padding-bottom: 60%;
    }
  }
  &.mosaic-3 .thumb-3 .thumb{
    padding-bottom: 50%;
  }
}

.section-quote {
  z-index: 25;
  position: relative;
  &.portfolio .quote-wrapper {
    @include resp-calc(padding-top, 60, 40, 40, 50);
    @include resp-calc(padding-bottom, 80, 60, 60, 70);
    padding-left: 0;
    padding-right: 0;
    background-color: #FFF;
  }
  .quote-box {
    background-color: #4D4D4D;
    @include resp-calc(padding-bottom, 80, 60, 60, 70);
  }
  .quote {
    font-family: 'Harmonia-Regular';
    line-height: 1.4;
    @include resp-calc(font-size, 22, 24, 26, 38);
    color: #FFFFFF;
    text-align: center;
    @include resp-calc(padding-top, 65, 40, 50, 50);
  }
  .quote:before {
    font-family: 'PTSerif-Regular';
    @include resp-calc(font-size, 66, 75, 95, 114);
    display: block;
    text-align: center;
    content: '\201C';
    @include resp-calc(height, 40, 50, 70, 70);
  }
  .quote-author {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 18, 16, 18, 22);
    @include resp-calc(padding-top, 35, 25, 40, 40);
    color: #FFFFFF;
    text-align: center;
    opacity: 0.55;
  }
  .quote-author-position {
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 14, 16, 18, 22);
    color: #FFFFFF;
    text-align: center;
    opacity: 0.55;
  }
}

.video-mosaic{
  font-size: 0;
  background-color: #000;
  .thumb-wrapper {
    @include resp-val(width, 100%, 50%, 50%, 33.333%);
    overflow: hidden;
    display: inline-block;
    position: relative;
    &:nth-child(n+2) {
      @include breakpoint(small only) {
        display: none;
      }
    }
    &:nth-child(n+5) {
      @include breakpoint(large down) {
        display: none;
      }
    }
    .play-button {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      @include sprite-icon($icon-play);
      z-index: 1;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  .video-thumb{
    width: 100%;
  }
}

/*=[ APPLY NOW ]==============================================================*/

.section-apply {
  .apply-wrapper {
    background-color: #FFF;
    padding: 0;
  }
  .apply {
    display: block;
		background-color: $sdblue;
    color: #FFF;
		border-radius: 3px;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 13, 12, 17, 20);
    @include resp-calc(line-height, 42, 38, 53, 63);
		@include resp-calc(height, 42, 38, 53, 63);
    margin-top: 40px;
    margin-bottom: 100px;
    @include resp-calc(margin-left, 20, 100, 0, 0);
		width: 100%;
		@include tile-highlight(none);
		transition: color 300ms ease, background-color 300ms ease;
    text-align: center;
		&:hover{
			background-color: $sdblue-dark;
		}
	}
}

/*=[ RELATED ]================================================================*/

.section-related{
  @include resp-calc(margin-top, 40, 50, 90, 90);
  @include resp-calc(padding-bottom, 5, 5, 70, 70);

  .related-container {
    display: flex;
    align-items: stretch;
  }

  .title{
    font-family: 'Harmonia-SemiBd';
    @include resp-calc(font-size, 18, 21, 32, 40);
    text-align: center;
    color: #191919;
    padding-bottom: 20px;
  }

  .tile{
    @include tile-highlight(light);
    background-color: #FFF;
    margin: 2px;
  }

  .thumb{
    padding-bottom: 70%;
    background-position: center;
    background-size: cover;
    margin: 0;
  }

  .thumb-content {
    color: #191919;
    @include resp-calc(padding-left, 10, 35, 35, 35);
    @include resp-calc(padding-right, 10, 35, 35, 35);
    @include resp-calc(padding-top, 10, 10, 30, 35);
    padding-bottom: 0;

    .tile-type {
      display: inline;
      font-family: 'Harmonia-Regular';
      @include resp-calc(font-size, 10, 11, 17, 22);
      line-height: 1.6;
    }
    .tile-title{
      @include resp-calc(padding-bottom, 20, 40, 40, 40);
      @include resp-calc(padding-top, 5, 5, 10, 10);
    }
    .tile-title a{
      font-family: 'Harmonia-Bold';
      @include resp-calc(font-size, 12, 15, 24, 26);
      line-height: 1.3;
      color: #191919;
    }
  }

}
