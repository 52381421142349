/*=[ CONTACT ]================================================================*/

.contact-start {
	background-color: $sdblue;
	@include resp-calc(padding-top, 210, 330, 310, 320);
	@include resp-calc(padding-bottom, 350, 430, 380, 340);
	padding-left: 60px;
	padding-right: 60px;

	.heading{
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 44, 52, 54, 82);
		text-align: center;
		line-height: 1.2;
		color: #FFF;
	}
	.subheading{
		color: #FFFFFF;
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 15, 17, 20, 30);
		line-height: 1.2;
		text-align: center;
	}
}

.contact-tiles{
	.tiles-wrapper {
		z-index: 30;
		@include resp-calc(margin-top, -135, -165, -180, -215);
		@include resp-calc(padding-left, 20, 0, 0, 0);
		@include resp-calc(padding-right, 20, 0, 0, 0);
	}
	.tile {
		background: transparent;
		padding: 2px;
		margin-bottom: 0;
	}
	h2 {
		background-color: #FFF;
		@include resp-calc(padding-top, 30, 50, 60, 70);
		text-align: center;
		@include resp-calc(height, 76, 126, 158, 186);
		@include resp-calc(font-size, 17, 24, 32, 34);
		font-family: 'Harmonia-Bold';
		a {
			color: #333;
		}
	}
}

.contact-footer{
	@include resp-calc(padding-top, 40, 40, 40, 100);
	@include resp-calc(padding-bottom, 60, 80, 130, 140);

	.time{
		margin-bottom: 30px;
		text-align: center;
		span{
			background-color: $sdblue;
			padding: 20px 10px 10px 10px;
			margin-left: 2px;
			margin-right: 2px;
			font-family: 'Harmonia-Bold';
			@include resp-calc(font-size, 50, 56, 56, 72);
			line-height: 1;
			color: #FFF;
		}
		.jsgif {
			display: inline-block;
			&:nth-child(1){
				@include resp-val(transform, translateX(31px), translateX(35px),
					translateX(35px), translateX(43px));
			}
			@include resp-calc(width, 110, 120, 120, 147);
			@include resp-calc(height, 110, 120, 120, 147);
			canvas {
				width: 100%;
				height: 100%;
			}
		}
		/*.hours, .minutes {
			display: none;
			@include resp-calc(width, 110, 120, 120, 147);
			@include resp-calc(height, 110, 120, 120, 147);
		}
		.hours{
			@include resp-val(transform, translateX(31px), translateX(35px),
				translateX(35px), translateX(43px));
		}*/
	}
	.office{
		font-family: 'Harmonia-Bold';
		@include resp-calc(padding-top, 10, 10, 10, 10);
		@include resp-calc(font-size, 24, 28, 32, 38);
		text-align: center;
	}
	.contact-person {
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 14, 16, 18, 18);
		line-height: 1.5;
		text-align: center;
		@include resp-calc(padding-top, 10, 10, 10, 10);
		@include resp-calc(padding-bottom, 10, 10, 10, 10);
	}
	address{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 14, 16, 18, 18);
		line-height: 1.5;
		text-align: center;
		color: #191919;
		font-style: normal;
	}
	.get-directions {
		margin: 0 auto;
		width: 210px;
	}
	.office-photo{
		padding-bottom: 70%;
		background-size: cover;
		margin-top: 40px;
	}
	.nyc .office-photo{
		@include resp-calc(margin-left, 24, 76, 2, 2);
		margin-right: 2px;
	}
	.ldn .office-photo{
		margin-left: 2px;
		@include resp-calc(margin-right, 24, 76, 2, 2);
	}
}

/*=[ CONTACT PAGE ]===========================================================*/

.contact-page{
	background-color: $sdblue;
	min-height: 100vh;
	padding-bottom: 140px;
	@include resp-calc(padding-left, 20, 30, 0, 0);
	@include resp-calc(padding-right, 20, 30, 0, 0);
	.back {
		@include resp-calc(padding-top, 90, 95, 140, 150);
		@include resp-calc(padding-bottom, 60, 65, 60, 100);
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(font-size, 16, 15, 16, 18);
		a{
			color: #007085;
		}
		& a:before {
			content: ' ';
			display: inline-block;
			margin-right: 5px;
			@include resp-sprite-icon-hidpi(
				$icon-back-small,
				$icon-back-medium,
				$icon-back-large,
				$icon-back-xlarge
			);
		}
	}
	h1{
		border-bottom: 1px solid #00A9C7;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 38, 38, 48, 48);
		color: #FFF;
		display: block;
		&.no-subtitle{
			@include resp-calc(margin-bottom, 30, 40, 30, 60);
		}
	}
	.subtitle{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 16, 20, 20, 26);
		color: #007085;
		padding-top: 20px;
		line-height: 1.2;
		@include resp-calc(margin-bottom, 30, 40, 30, 60);
		a{
			color: #FFF;
		}
	}
	.subtitle.warning{
		padding-top: 30px;
		@include resp-calc(font-size, 16, 16, 16, 20);
		a {
			font-style: italic;
			text-decoration: underline;
			color: inherit;
			transition: color 300ms ease;
		}
		a:hover {
			color: #FFF;
		}
	}
	#toggler-panel,  #toggler-panel li{
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 12, 12, 16, 18);
		line-height: 1.2;
		color: #007085;
	}
	#toggler-panel {
		margin-bottom: 20px;
	}
	#toggler-panel li {
		@include resp-calc(margin-left, 20, 20, 40, 40);
	}
	.info {
		padding-bottom: 10px;
	}
	input {
		@include resp-calc(height, 38, 38, 38, 46, !important);
	}
	input, .input, input:focus, textarea, textarea:focus {
		@include resp-calc(font-size, 16, 16, 16, 26, !important);
	}
	label {
		@include resp-calc(font-size, 11, 12, 12, 18, !important);
	}
	.heading{
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(font-size, 12, 12, 12, 17);
		color: #FFF;
		padding-bottom: 10px;
		@include resp-calc(margin-left, 5, 0, 0, 0);
		@include resp-calc(margin-right, 5, 0, 0, 0);
	}
	.contact-wrapper {
		@include resp-calc(padding-top, 0, 45, 55, 70);
		@include resp-calc(padding-left, 0, 20, 20, 20);
		@include resp-calc(padding-right, 0, 0, 20, 20);
	}
	.contact-person-2{
		display: flex;
		.contact-box {
			width: 50%;
		}
	}
	.contact-box {
		background-color: #FFF;
		@include resp-calc(padding-top, 20, 30, 30, 30);
		@include resp-calc(padding-bottom, 10, 15, 15, 15);
		@include resp-calc(margin-bottom, 55, 15, 15, 15);
		@include resp-calc(margin-left, 5, 0, 0, 0);
		@include resp-calc(margin-right, 5, 0, 0, 0);

		.thumb{
			margin: auto;
			@include resp-round-thumb(84, 84, 84, 104);
		}
		.name {
			text-align: center;
			font-family: 'Harmonia-Bold';
			@include resp-calc(font-size, 14, 14, 14, 17);
			padding-top: 15px;
			line-height: 1;
			color: #333333;
		}
		.position{
			text-align: center;
			font-family: 'Harmonia-Regular';
			@include resp-calc(font-size, 14, 14, 14, 17);
			color: #4C4C4C;
		}
		.contact-icons{
			@include resp-calc(padding-top, 5, 15, 15, 15);
			text-align: center;
			.mail {
				margin-right: 15px;
				@include resp-calc(width, 20, 20, 20, 24);
				@include resp-calc(height, 20, 20, 20, 24);
				display: inline-block;
				@include resp-sprite-icon(
				$icon-mail-large,
				$icon-mail-large,
				$icon-mail-large,
				$icon-mail-xlarge);
			}
			.rule{
				width: 1px;
				height: 100%;
				border-left: 1px solid #E6E6E6;
			}
			.tel {
				margin-left: 15px;
				@include resp-calc(width, 20, 20, 20, 24);
				@include resp-calc(height, 20, 20, 20, 24);
				display: inline-block;
				@include resp-sprite-icon(
				$icon-tel-large,
				$icon-tel-large,
				$icon-tel-large,
				$icon-tel-xlarge);
			}
		}
	}
}

/*=[ CONTACT FORM ]===========================================================*/

.contact-form {
	.half-row{
		@extend .large-6;
		@extend .medium-6;
		@extend .small-12;
		@extend .columns;
	}
	.full-row{
		@extend .large-12;
		@extend .medium-12;
		@extend .small-12;
		@extend .columns;
	}
	.input-row {
		.columns {
			padding-left: 10px;
			padding-right: 10px;
		}
		&.subscribe {
			@include resp-calc(padding-top, 20, 30, 30, 30);
			@include resp-calc(padding-bottom, 200, 200, 300, 300);
		}
	}
	label {
		color: #FFFFFF;
		font-family: 'Harmonia-SemiBd';
		@include resp-calc(padding-top, 5, 5, 10, 10);
		@include resp-calc(font-size, 11, 11, 15, 18);
		padding-bottom: 5px;
	}
	input, .input,
	input:focus, textarea, textarea:focus {
		background-color: #00A4C1;
		border-radius: 3px;
		font-family: 'Harmonia-Regular';
		@include resp-calc(font-size, 17, 15, 21, 26);
		padding-left: 15px;
		padding-right: 15px;
		color: #FFF;
	}
	input, .input {
		@include resp-calc(height, 34, 34, 48, 57);
	}
	input, textarea {
		border: none;
	}
	input:focus, textarea:focus {
		border: 2px solid #FFF;
	}
	textarea{
		@include resp-calc(margin-top, 25, 25, 20, 30);
		@include resp-calc(margin-bottom, 45, 30, 45, 100);
	}
	::-webkit-input-placeholder {
		color: #FFF;
		opacity: 0.3;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #FFF;
		opacity: 0.3;
	}
	::-moz-placeholder {  /* Firefox 19+ */
		color: #FFF;
		opacity: 0.3;
	}
	:-ms-input-placeholder {
		color: #FFF;
		opacity: 0.3;
	}
	// http://zurb.com/building-blocks/file-upload-button
	.file-upload {
		position: relative;
		overflow: hidden;
		width: 100%;
		color: rgba(255, 255, 255, 0.3);
		text-align: left;
		@include resp-calc(padding-left, 50, 72, 72, 72);
		@include resp-calc(margin-bottom, 45, 30, 45, 100);
		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0;
			width: 57px;
			height: 57px;
			@include resp-sprite-icon(
			$icon-attach-small,
			$icon-attach-medium,
			$icon-attach-large,
			$icon-attach-xlarge);
		}
		.file-input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			cursor: pointer;
			opacity: 0;
		}
		.filename.active {
			color: #FFF;
		}
	}

	a.submit {
		display: block;
		text-align: center;
		cursor: pointer;
		@include resp-calc(line-height, 42, 38, 53, 63);
	}
	.submit {
		background-color: #FFF;
		border-radius: 3px;
		color: $sdblue;
		font-family: 'Harmonia-Bold';
		@include resp-calc(font-size, 13, 12, 17, 20);
		@include resp-calc(height, 42, 38, 53, 63);
		width: 100%;
		@include tile-highlight(none);
		transition: color 300ms ease, background-color 300ms ease;
		&:hover{
			color: #FFF;
			background-color: $sdblue-dark;
		}
	}
	.submit.disabled {
		opacity: 0.5;
	}
	.submit.disabled:hover {
		@include remove-tile-highlight();
	}
	.progress {
		display: none;
		margin-top: 2rem;
		background-color: transparent;
		position: relative;
		width: 100%;
		height: 3rem;
		border: 1px solid rgba(255, 255, 255, 0.6);
	}
	.bar {
		background-color: rgba(255, 255, 255, 0.6);
		width: 0%;
		height: 3rem;
		margin-top: -1px;
	}
	.percent {
		position: absolute;
		display: inline-block;
		color: #007084;
		top: 10px;
		left: 48%;
	}
	#message-sent,
	.upload-error {
		display: none;
	}

	&.subscribe {
		margin-top: 100px;
		.email {
			width: calc(100% - 20px);
		}
		.submit {
			color: #FFF;
			background-color: $sdblue-dark;
			@include resp-calc(height, 34, 34, 48, 57);
			&:hover{
				color: $sdblue;
				background-color: #FFF;
			}
		}
		#message-sent{
			color: #333;
		}
	}
}
