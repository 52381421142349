/*=[ INTRO ]==================================================================*/

.intro-work {
  background-color: #FFFFFF;
  @include resp-calc(padding-top, 190, 280, 270, 360);
  @include resp-calc(padding-bottom, 160, 210, 300, 300);

  h1 {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 44, 52, 54, 82);
    line-height: 1;
    text-align: center;
    color: $type-black;
  }
  h2{
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 15, 17, 17, 30);
    padding: 15px 50px 0px 50px;
    text-align: center;
    color: #4C4C4C;
  }
}

/*=[ PROJECTS ]===============================================================*/

.section-wrapper:before{
  @include sprite-icon($icon-arrow-down-black);
  display: block;
  content: '';
  margin: 0 auto 30px auto;
  z-index: 10;
  @include resp-calc(margin-top, -40, -40, -130, -130);
}

.section-work{

  padding: 0 2px;
  background-color: #F7F7F7;
  border-top: 1px solid #FFF;
  @include resp-calc(margin-bottom, 2, 2, 110, 110);

  .thumb.light .thumb-content {
    color: #191919;
  }

  .thumb-content {
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    @include resp-calc(padding-top, 20, 20, 30, 40);
    @include resp-calc(padding-left, 20, 20, 30, 40);
    h3 {
      text-align: left;
      font-family: 'Harmonia-Bold';
      line-height: 1.3;
    }
    h4{
      text-align: left;
      font-family: 'Harmonia-Regular';
    }
  }

  .thumb-content{
    h3 {
      @include resp-calc(font-size, 22, 22, 26, 30);
    }
    h4 {
      @include resp-calc(font-size, 16, 16, 22, 24);
    }
  }

  @include breakpoint(small) {
    .thumb.full .image{
      padding-bottom: 100%;
    }
    .thumb.half .image{
      padding-bottom: 100%;
    }
    .thumb.quarter .image{
      padding-bottom: calc(50% - 2px);
    }
  }
  @include breakpoint(medium up) {
    .thumb.full .image{
      padding-bottom: 50%;
    }
    .thumb.half .image{
      padding-bottom: 100%;
    }
    .thumb.quarter .image{
      padding-bottom: calc(50% - 15px);
    }
  }

  .video-thumb-wrapper{
    position: relative;
    overflow-x: hidden;
    clip-path: inset(0 2px 0 0px);
  }

  .thumb{
    position: relative;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    margin: 15px 15px;
    @include tile-highlight(dark);
    &.light {
      @include tile-highlight(light);
    }
    &.video-thumb{
      line-height: 0; // remove extra space below video
      video{
        width:100%;
      }
    }
  }

}

/*=[ IDEAS ]==================================================================*/

.section-thinking {
  @include resp-calc(margin-bottom, 2, 2, 110, 110);
  .hero {
    background-color: #FFF;
    @include breakpoint(large up) {
      @include tile-highlight(light);
    }
    @include breakpoint(medium down) {
      @include tile-highlight(dark);
      &.light{
        @include tile-highlight(light);
      }
    }
    @include resp-calc(margin-top, 0, 22, 0, 25);
    @include resp-calc(margin-bottom, 4, 45, 45, 75);
    @include resp-calc(height, 374, 384, 450, 540);
    .thumb-wrapper{
      padding: 0 2px;
    }
    &.light .thumb .thumb-title-type {
      color: #191919;
    }
    .thumb {
      overflow: hidden;
      position: relative;
      .image {
        background-position: center;
        background-size: cover;
        @include resp-calc(height, 374, 384, 450, 540);
      }
      .thumb-title-type{
        color: #FFF;
        position: absolute;
        top: 0;
        z-index: 5;
        @include resp-calc(padding-top, 20, 20, 20, 20);
        @include resp-calc(padding-left, 30, 30, 30, 30);
        @include resp-calc(padding-right, 30, 30, 30, 30);
        .thumb-title{
          font-family: 'Harmonia-Bold';
          @include resp-calc(font-size, 24, 24, 24, 24);
        }
        .thumb-type{
          font-family: 'Harmonia-Regular';
          @include resp-calc(font-size, 16, 14, 14, 14);
        }
      }

    }
    .thumb-content{
      @include resp-calc(padding-top, 75, 75, 30, 40);
      .title-preview{
        @include resp-calc(height, 340, 340, 298, 340);
      }
    }
  }
}

.section-thinking, .section-news{
  .more a{
    text-align: center;
    font-family: 'Harmonia-SemiBd';
    @include resp-calc(font-size, 15, 15, 16, 16);
    color: #4C4C4C;
    display: block;
    @include resp-calc(height, 70, 95, 100, 100);
    @include resp-calc(line-height, 70, 95, 100, 100);
    transition: color 300ms ease;
    &:hover{
      color: $sdblue;
    }
  }

  .newsletter-link {
    padding-top: 20px;
    padding-bottom: 20px;
    @include resp-calc(margin-bottom, 7, 0, 40, 20);
    background-color: #FFF;
  }
}


/*=[ TILE ]===================================================================*/

.tile {
  padding: 0;
  @include tile-highlight(light);
  @include resp-calc(margin-bottom, 4, 22, 44, 50);
  background-color: transparent;

  .thumb {
    overflow: hidden;
    margin: 15px 15px 0 15px;
  }
  .thumb .image{
    padding-bottom: 70%;
    background-position: center;
    background-size: cover;
  }
  .thumb-content {
    margin: 0 15px 15px 15px;
    background-color: #FFF;
  }
}

.tile .thumb-content, .hero .thumb-content {
  color: #191919;
  @include resp-calc(padding-left, 25, 30, 30, 35);
  @include resp-calc(padding-right, 25, 30, 30, 35);
  @include resp-calc(padding-top, 20, 15, 20, 20);
  @include resp-calc(padding-bottom, 70, 50, 5, 15);
  .tile-type {
    display: inline-block;
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 14, 14, 17, 20);
    line-height: 1.2;
    @include resp-calc(margin-bottom, 10, 0, 10, 20);
    border-bottom: 1px solid $sdblue;
  }
  .title-preview {
    @include resp-calc(height, 220, 220, 240, 280);
    overflow-y: hidden;
  }
  .tile-title{
    @include resp-calc(padding-top, 0, 20, 0, 10);
  }
  .tile-title a {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 22, 23, 24, 28);
    line-height: 1.3;
    color: #191919;
  }
  .article-preview {
    font-family: 'Harmonia-Regular';
    @include resp-calc(font-size, 15, 16, 17, 20);
    @include resp-calc(padding-top, 10, 10, 5, 10);
    line-height: 1.5;
    color: #191919;
  }
  .author {
    .author-thumb{
      @include resp-round-thumb(46, 50, 50, 86);
      float: left;
      margin-right: 10px;
      border-radius: 50%;
      border: 3px solid #FFF;
    }
    .coauthor-thumb{
      @extend .author-thumb;
      @include resp-calc(margin-left, -20, -20, -20, -25);
    }
    .badge-text {
      @include resp-calc(padding-top, 16, 18, 17, 34);
      @include resp-calc(height, 20, 20, 80, 90);
      .author-name {
        font-family: 'Harmonia-SemiBd';
        line-height: 1.2;
        @include resp-calc(font-size, 14, 14, 16, 17);
      }
      .article-date {
        font-family: 'Harmonia-Regular';
        @include resp-calc(font-size, 14, 14, 14, 17);
        color: #7F7F7F;
      }
    }
  }
}

/*=[ NEWS ITEM ]==============================================================*/

.intro-work.news {
  background-color: $sdblue;
  h1, h2{
    color: #FFF;
  }
  h2 {
    opacity: 0.75;
  }
}

.section-news {
  background-color: #FFF;
  @include resp-calc(padding-top, 25, 50, 80, 80);
  @include resp-calc(padding-bottom, 30, 30, 170, 250);
  &.section-wrapper:before{
    display: none;
  }

  .tile-container {
    transition: background-color 300ms ease;
    &:hover {
      background-color: #F7F7F7;
    }
  }

  .thumb{
    .thumb-wrapper {
      @include resp-calc(margin-left, 0, 1, 0, 0);
      @include resp-calc(margin-right, 0, 1, 0, 0);
    }
    .image-wrapper {
      padding: 0;
      overflow: hidden;
    }
    .image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding-bottom: 70%;
    }
    .thumb-content{
      @include resp-calc(padding, 25, 50, 80, 80);
      .topic {
        font-family: 'Harmonia-Regular';
        @include resp-calc(font-size, 14, 14, 17, 17);
        display: inline-block;
        border-bottom: 1px solid $sdblue;
        line-height: 1.2;
      }
      .heading{
        font-family: 'Harmonia-Bold';
        @include resp-calc(font-size, 22, 23, 24, 28);
        @include resp-calc(padding-top, 5, 20, 30, 20);
        line-height: 1.2;
      }
      .subheading{
        font-family: 'Harmonia-Regular';
        @include resp-calc(font-size, 15, 16, 17, 20);
        @include resp-calc(padding-top, 10, 5, 5, 5);
      }
      .date {
        padding-top: 10px;
        font-family: 'Harmonia-Regular';
        @include resp-calc(font-size, 14, 14, 14, 17);
        color: #7F7F7F;
      }
    }
  }
}

/*=[ SEARCH ]=================================================================*/

.section-search-results {
  background-color: #FFF;
  @include resp-calc(padding-top, 100, 100, 100, 150);
  @include resp-calc(padding-bottom, 60, 60, 100, 170);

  .results-wrapper {
    @include resp-calc(padding-left, 20, 20, 0, 0);
    @include resp-calc(padding-right, 20, 20, 0, 0);
  }

  .query {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 28, 28, 34, 65);
    @include resp-calc(padding-bottom, 0, 30, 30, 70);
    &::first-letter{
      text-transform: uppercase;
    }
  }

  .type {
    font-family: 'Harmonia-Bold';
    @include resp-calc(font-size, 12, 16, 18, 18);
    color:$sdblue;
    @include resp-calc(padding-top, 20, 40, 20, 20);
    border-bottom: 1px solid #EAEAEA;
    line-height: 2;
    margin-bottom: 10px;
  }

  .search-thumb {
    @include resp-calc(width, 78, 110, 110, 110);
    @include resp-calc(height, 48, 80, 80, 80);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-right: 15px;
    float: left;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Harmonia-SemiBd';
    @include resp-calc(font-size, 14, 18, 22, 22);
    color: #191919;
    @include resp-calc(height, 56, 88, 88, 88);
    margin-bottom: 8px;
    overflow: hidden;
    border-bottom: 1px solid #EAEAEA;
  }

}
